import { useRouter } from "next/router";
import Landing from "../../pages";
import Main from "../../pages/main";
import DefaultErrorPage from "next/error";
import { useEffect } from "react";

type Props = {
  children: JSX.Element;
  isReady: any;
  query: any;
};

const AuthGuard: React.FC<Props> = ({ children, isReady, query }) => {
  const router = useRouter();
  const pages = [
    "/",
    "/about",
    "/animoca",
    "/how-it-works",
    "/join",
    "/memberships",
    "/main",
    "/locations",
    "/invite",
  ];

  if (!pages.includes(router.pathname)) {
    return <DefaultErrorPage statusCode={404} />;
  }

  if (query && !query.hasOwnProperty("utm_campaign")) {
    return router.pathname === "/" ? <Main /> : children;
  } else {
    return router.pathname === "/" ? <Landing /> : children;
  }
};

export default AuthGuard;
