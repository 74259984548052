import React, { useContext, useEffect, useState } from "react";
import { motion, useScroll } from "framer-motion";
import ThemeContext from "../../contexts/Main";
import Icon from "../Icons";

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -25 },
};

export default function Nav() {
  const { theme } = useContext(ThemeContext);
  // const scrollWrapper: any = useScroll();
  // const [hidden, setHidden] = useState(false);
  // const [fixed, setFixed] = useState(false);

  // function update() {
  //   const elemHeight = document.getElementById('header-section-1') as HTMLDivElement | null
  //   if (
  //     elemHeight && scrollWrapper.scrollY?.current > (elemHeight?.offsetHeight - 20)
  //   ) {
  //     setHidden(true);
  //   } else {
  //     setHidden(false);
  //   }
  // }

  // useEffect(() => {
  //   return scrollWrapper.scrollY.onChange(() => update());
  // });

  return (
    <>
      <motion.div
        variants={variants}
        // animate={hidden ? "hidden" : "visible"}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        className={`flex justify-center items-center h-[465px] header-section lg:flex-col md:h-[375px]`}
        id="header-section-1"
      >
        <Icon icon="club3-logo" className="club3-la-logo" size={375} />
        <div className="fnt-regular header-content w-[449px] ml-[95px] lg:ml-0 lg:pt-5 md:w-[235px] md:ml-[0px] lg:text-center md:pt-10">
          A first of its kind private club reimagined exclusively for the{" "}
          <span className="fnt-bold">Web3 community</span>.{" "}
        </div>
      </motion.div>
      {/* <motion.div
        variants={variants}
        animate={hidden ? "visible" : "hidden"}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        className={hidden ? '' : 'hidden'}
        id="header-section-2"
      >
        <div className={`flex header-section-2 p-6 pl-[60px] md:pl-[20px]`} id="header-fixed">
          <Icon icon="club3-la-logo" size={100} />
        </div>
        <div className="flex justify-center px-[30px]">
          <div className="fnt-regular header-content-2 w-[845px] text-center light-green-text pt-[60px] lg:w-full sm:w-[295px] md:pt-4">
            A first of its kind private club reimagined exclusively for the{" "}
            <span className="fnt-bold">Web3 community</span>.{" "}
          </div>
        </div>
      </motion.div> */}
      <style jsx global>{`
        .header-section {
          background: ${theme.green};
        }
        .header-section-2 {
          background: ${theme.green};
        }
        .header-content {
          font-size: ${theme.sizes.regularTitle.h4.fontSize}px;
          line-height: ${theme.sizes.regularTitle.h4.lineHeight}px;
        }
        .header-content-2 {
          font-size: ${theme.sizes.boldTitle.h6.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h6.lineHeight}px;
        }
        .fixed-header {
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 1;
          border-bottom: 1px solid ${theme.lightGreen};
        }
        :global(svg.club3-la-logo path) {
          fill: ${theme.bgPrimary};
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .header-content,
          .header-content-2 {
            font-size: ${theme.sizes.content.p4.fontSize}px;
            line-height: ${theme.sizes.content.p4.lineHeight}px;
          }
          :global(svg.club3-la-logo) {
            height: 180px !important;
            width: 260px !important;
          }
        }
      `}</style>
    </>
  );
}
