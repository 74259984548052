import React, { useRef, useState } from "react";
import Icon from "../../Icons";
import ReCAPTCHA from "react-google-recaptcha";
import Button, { ButtonKind, ButtonSize } from "../../Button";

type Props = {
  showModel: boolean;
  toggleModel: () => void;
  theme: any;
};

function ValidateEmail(inputText: string) {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
  return inputText.match(mailformat);
}

const ContactModel: React.FC<Props> = ({ showModel, toggleModel, theme }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const reRef = useRef<ReCAPTCHA>();

  const handleInputChange = (inputValue: string, inputName: string) => {
    setForm({
      ...form,
      [inputName]: inputValue,
    });
  };

  const validateForm = (inputs: any) => {
    const errors: any = {};
    if (!inputs.email.trim()) {
      errors.email = "This is required field";
    } else if (!ValidateEmail(inputs.email)) {
      errors.email = "Please enter valid email";
    }
    if (!inputs.name.trim()) {
      errors.name = "This is required field";
    }
    if (!inputs.message.trim()) {
      errors.message = "This is required field";
    }
    return errors;
  };

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    setFormErrors({});
    const errors = validateForm(form);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setLoading(true);
      const token = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      const formBody = { ...form, token: token };
      const response = await fetch("/api/message", {
        method: "POST",
        body: JSON.stringify(formBody),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      setLoading(false);
      const { data, error } = await response.json();
      if (error) {
        alert(`Something went wrong: ${error}`);
      } else {
        alert("Submission received");
        setForm({
          name: "",
          email: "",
          message: "",
        });
        toggleModel()
      }
    }
  };

  return (
    <div className={`popup ${showModel ? "active" : "inactive"}`}>
      <div className="overlay"></div>
      <div className="popup-content">
        <div className="contact-us">
          <div className="flex justify-between">
            <h3 className="fnt-semiMedium light-green-text">
              More Questions?
              <br /> <span>Get in Touch</span>
            </h3>
            <Icon
              icon="cross"
              size={30}
              className="cursor-pointer"
              onClick={toggleModel}
              color={theme.fontColorPrimary}
            ></Icon>
          </div>
          <div className="mt-7">
            <ReCAPTCHA
              sitekey={String(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY)}
              size="invisible"
              ref={reRef as React.RefObject<ReCAPTCHA>}
            />
            <form onSubmit={handleFormSubmit}>
              <div className="form-group mb-6">
                <input
                  type="text"
                  className={`form-control w-full custom-input ${
                    formErrors?.name ? "red-border" : ""
                  }`}
                  placeholder="Enter name*"
                  value={form.name}
                  onChange={(e: any) =>
                    handleInputChange(e.target.value, "name")
                  }
                  required
                />
                {formErrors?.name && (
                  <div className="text-sm mt-1 red-text">{formErrors.name}</div>
                )}
              </div>
              <div className="form-group mb-6">
                <input
                  type="email"
                  className={`form-control w-full custom-input ${
                    formErrors?.name ? "red-border" : ""
                  }`}
                  placeholder="Enter email*"
                  value={form.email}
                  onChange={(e: any) =>
                    handleInputChange(e.target.value, "email")
                  }
                  required
                />
                {formErrors?.email && (
                  <div className="text-sm mt-1 red-text">
                    {formErrors.email}
                  </div>
                )}
              </div>
              <div className="form-group mb-6">
                <textarea
                  className={`form-control w-full custom-input input-resize ${
                    formErrors?.name ? "red-border" : ""
                  }`}
                  rows={6}
                  placeholder="Enter message*"
                  value={form.message}
                  onChange={(e: any) =>
                    handleInputChange(e.target.value, "message")
                  }
                  required
                />
                {formErrors?.message && (
                  <div className="text-sm mt-1 red-text">
                    {formErrors.message}
                  </div>
                )}
              </div>
              <Button
                kind={ButtonKind.primary}
                size={ButtonSize.large}
                cta={true}
                className="min-w-[100%] submit-btn"
                showLoader={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        .popup.active {
          display: block;
          width: 100vw;
          min-height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000;
          min-width: 375px;
        }
        .overlay {
          width: 100%;
          height: 100%;
          background-color: #dfdfdfe6;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        .popup.inactive {
          display: none;
        }
        .popup-content {
          height: fit-content;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background: #121212;
          box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.5);
          border-radius: 8px;
          width: 50%;
          background: ${theme.bgPrimary};
          padding: 20px;
        }
        .contact-us h3 {
          font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h3.lineHeight}px;
        }
        .contact-us h3 span {
          color: ${theme.green};
        }
        .input-resize {
          resize: none;
        }
        .red-border {
          border: 1px solid ${theme.instaBg};
        }
        .red-text {
          color: ${theme.instaBg};
        }
        .custom-input {
          background: ${theme.lighterGreen};
          padding: 25px 0px 25px 24px;
          border-radius: 20px;
        }
        .custom-input::placeholder {
          color: ${theme.fontColorPrimary};
        }
        .custom-input:focus {
          outline: none !important;
          border: 1px solid ${theme.green};
        }
        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .popup-content {
            width: 70%;
          }
          .contact-us h3 {
            font-size: ${theme.sizes.content.p2.fontSize}px;
            line-height: ${theme.sizes.content.p2.lineHeight}px;
          }
          input.custom-input {
            height: 48px;
          }
          :global(.contact-us .submit-btn) {
            padding: 10px 50px;
            min-height: 50px;
            font-size: ${theme.sizes.content.p7.fontSize}px;
            line-height: ${theme.sizes.content.p7.lineHeight - 1}px;
            font-family: ${theme.fonts.semiMedium.name};
            font-weight: ${theme.fonts.semiMedium.weight};
          }
        }
        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .popup-content {
            width: 90%;
          }
        }
      `}</style>
    </div>
  );
};

export default ContactModel;
