import React, { useContext, useEffect } from "react";
import ThemeContext from "../../contexts/Main";
import Marquee from "../Marquee";
import Icon from "../Icons";
import GetInTouch from "./GetInTouch";
import Header from "./Header";
import { useRouter } from "next/router";
import Link from "next/link";
import Button, { ButtonKind, ButtonSize } from "../Button";

type Props = {};
const STRIPE_CLU3_ACCESS_TOKEN = "f495070cabef89be5a8d328457357c0a";

const HomePage: React.FC<Props> = () => {
  const { theme } = useContext(ThemeContext);
  const { isReady, query } = useRouter();
  const { access } = query;
  const stripeUser = access === STRIPE_CLU3_ACCESS_TOKEN;

  if (!isReady) {
    return null;
  }

  return (
    <div className="main-page fnt-regular">
      <Header />
      <Link href="#register">
        <a className="pt-[80px] md:pt-8 block w-max m-auto">
          <Button kind={ButtonKind.primaryCta} size={ButtonSize.medium}>
            Register Now
          </Button>
        </a>
      </Link>
      <div className="flex connection-section justify-around pt-[80px] px-[80px] pb-[58px] xl:px-[30px] lg:flex-col md:pt-8 md:pb-10">
        <div className="card flex p-5 lg:mb-5">
          <Icon icon="link" size={30} />
          <div className="pl-[15px]">
            <div className="card-heading fnt-medium text-sm light-green-text">
              Connection & Collaboration
            </div>
            <div className="card-description text-xs w-[215px] pt-2.5">
              Your hub for connection and collaboration.{" "}
            </div>
          </div>
        </div>
        <div className="card flex p-5 lg:mb-5">
          <Icon icon="plant" size={30} />
          <div className="pl-[15px]">
            <div className="card-heading fnt-medium text-sm light-green-text">
              IRL Spaces for Personal Growth
            </div>
            <div className="card-description text-xs w-[215px] pt-2.5">
              IRL spaces to learn, challenge, advance, test, grow.
            </div>
          </div>
        </div>
        <div className="card flex p-5">
          <Icon icon="globe" size={30} />
          <div className="pl-[15px]">
            <div className="card-heading fnt-medium text-sm light-green-text">
              Access to Influential Network
            </div>
            <div className="card-description text-xs w-[215px] pt-2.5">
              Instant network of like minds, access to the biggest, most
              influential leaders in the Web3 space.{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="social-club flex flex-col items-center p-[30px] md:pt-0 md:px-[30px]">
        <h1 className="fnt-bold text-center light-green-text">
          Join an Ecosystem of Web3 Leaders and Builders
        </h1>
        <div className="social-description fnt-medium text-center w-[1055px] pt-5 green-text xl:w-full">
          Club 3 is backed by Animoca Brands and Planet Hollywood Group, leaders
          in blockchain technology, entertainment and hospitality.
        </div>
        <div className="grid grid-cols-2 gap-14 pt-11 pb-[60px] lg:grid-cols-1 lg:gap-6 md:pt-7 md:pb-5">
          <div className="card p-5">
            <div className="card-heading fnt-medium light-green-text">
              Part of the Animoca Brands Ecosystem
            </div>
            <div className="card-description text-base pt-6 md:text-xs md:pt-2.5">
              Club 3 is backed by Animoca Brands and is part of its extensive
              ecosystem.
            </div>
          </div>
          <div className="card p-5">
            <div className="card-heading fnt-medium light-green-text">
              Your IRL Hub & Homebase
            </div>
            <div className="card-description text-base pt-6 md:text-xs md:pt-2.5">
              Geared toward ideation and collaboration with the most influential
              minds of the global community.
            </div>
          </div>
          <div className="card p-5">
            <div className="card-heading fnt-medium light-green-text">
              Private Playground to Indulge
            </div>
            <div className="card-description text-base pt-6 md:text-xs md:pt-2.5">
              Luxurious outposts featuring cutting edge technology, expansive
              dining areas, and Web3 inspired Work x Play events.
            </div>
          </div>
          <div className="card p-5">
            <div className="card-heading fnt-medium light-green-text">
              Brought to Life by the Best
            </div>
            <div className="card-description text-base pt-6 md:text-xs md:pt-2.5">
              Dreamed up by an internationally recognized group of designers and
              architects.
            </div>
          </div>
        </div>
      </div>
      <div className="coming-soon">
        <p className="cs-subtitle light-green-text fnt-bold text-center pt-10 md:pt-6">
          Locations Coming Soon
        </p>
        <Marquee />
      </div>
      <div className="membership-nft flex flex-col items-center pt-[80px] pb-10 p-[30px] md:pt-10">
        {stripeUser ? (
          <h3 className="fnt-bold text-center light-green-text">Membership</h3>
        ) : (
          <h3 className="fnt-bold text-center light-green-text">
            Membership NFTs
          </h3>
        )}
        {stripeUser ? (
          <div className="social-description fnt-medium text-center w-[770px] pt-10 green-text lg:w-full md:pt-5">
            Embracing the Web3 ethos, our memberships are secured by a Club 3
            signature.
          </div>
        ) : (
          <div className="social-description fnt-medium text-center w-[770px] pt-10 green-text lg:w-full md:pt-5">
            Embracing the Web3 ethos, our memberships are secured by minting a
            Club 3 signature NFT.
          </div>
        )}

        <div className="grid grid-cols-4 gap-6 xl:grid-cols-2 pt-[70px] md:pt-5">
          <div className="card flex flex-col items-center p-5">
            <Icon icon="nft" size={45} />
            <div className="light-green-text text-xl w-[105px] text-center md:text-sm md:w-[90px] md:pt-2.5">
              Single, Simple Purchase Price
            </div>
          </div>
          <div className="card flex flex-col items-center p-5">
            <Icon icon="currency" size={45} />
            <div className="light-green-text text-xl w-[105px] text-center md:text-sm md:w-[90px] md:pt-2.5">
              Zero Application Fees, Zero Annual Fees
            </div>
          </div>
          <div className="card flex flex-col items-center p-5 xl:mt-5 md:mt-1">
            <Icon icon="hundered" size={45} />
            <div className="light-green-text text-xl w-[105px] text-center md:text-sm md:w-[90px] md:pt-2.5">
              {" "}
              Zero Restrictions, Just Total Freedom
            </div>
          </div>
          <div className="card flex flex-col items-center p-5 xl:mt-5 md:mt-1">
            <Icon icon="wallet" size={45} />
            <div className="light-green-text text-xl w-[105px] text-center md:text-sm md:w-[90px] md:pt-2.5">
              {" "}
              Lease, Sell, Transfer, or Upgrade Anytime
            </div>
          </div>
        </div>
      </div>
      <GetInTouch />

      <style jsx>{`
        .main-page {
          max-width: 1440px;
          margin: 0 auto;
          min-width: 375px;
        }
        .card {
          width: 360px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          border-radius: 20px;
        }
        .social-club,
        .membership-nft {
          background: linear-gradient(
              180deg,
              #ffffff -4.02%,
              #f1f1ea 51.2%,
              rgba(241, 241, 234, 0) 110.57%
            ),
            radial-gradient(
                168.06% 60.51% at 168.06% 31.31%,
                rgba(53, 107, 10, 0.1595) 0%,
                rgba(255, 255, 255, 0.55) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
            radial-gradient(
                58.47% 43.36% at -11.94% 47.9%,
                rgba(125, 222, 89, 0.154) 0%,
                rgba(255, 255, 255, 0.55) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
            radial-gradient(
                86.95% 86.95% at 91.25% 0%,
                rgba(125, 222, 89, 0.28) 1.55%,
                #ffffff 1.56%,
                #f0efff 63.59%,
                rgba(240, 239, 255, 0) 84.37%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        }
        .social-club .card {
          width: 445px;
        }
        .membership-nft .card {
          padding: 20px 60px;
          width: auto;
        }
      `}</style>
      <style jsx global>{`
        .fnt-bold {
          font-family: ${theme.fonts.bold.name};
          font-weight: ${theme.fonts.bold.weight};
        }
        .fnt-regular {
          font-family: ${theme.fonts.regular.name};
          font-weight: ${theme.fonts.regular.weight};
        }
        .fnt-medium {
          font-family: ${theme.fonts.medium.name};
          font-weight: ${theme.fonts.medium.weight};
        }
        .social-description {
          font-size: ${theme.sizes.regularTitle.h4.fontSize}px;
          line-height: ${theme.sizes.regularTitle.h4.lineHeight}px;
        }
        .header-content {
          color: ${theme.bgPrimary};
        }
        .card {
          background: ${theme.cardBg};
        }
        .social-club .card,
        .membership-nft .card {
          background: ${theme.bgPrimary};
        }
        .social-club h1,
        .cs-subtitle,
        .membership-nft h3 {
          font-size: ${theme.sizes.boldTitle.h5.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h5.lineHeight}px;
        }
        .green-text {
          color: ${theme.green};
        }
        .light-green-text {
          color: ${theme.lightGreen};
        }
        .social-club .card-heading {
          font-size: ${theme.sizes.mediumTitle.h7.fontSize}px;
          line-height: ${theme.sizes.mediumTitle.h7.lineHeight}px;
        }
      `}</style>
      <style jsx>{`
        @media screen and (max-width: ${theme.breakpoints.xl}px) {
          .connection-section .card {
            width: 300px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .connection-section .card {
            width: 100%;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .header-content {
            font-size: ${theme.sizes.content.p4.fontSize}px;
            line-height: ${theme.sizes.content.p4.lineHeight}px;
          }
          .social-club h1,
          .cs-subtitle,
          .membership-nft h3 {
            font-size: ${theme.sizes.content.p2.fontSize}px;
            line-height: ${theme.sizes.content.p2.lineHeight}px;
          }
          .social-description,
          .social-club .card-heading {
            font-size: ${theme.sizes.content.p8.fontSize}px;
            line-height: ${theme.sizes.content.p8.lineHeight}px;
          }
          .social-club .card {
            width: 100%;
          }
          .membership-nft .card {
            padding: 20px 75px;
          }
        }
      `}</style>
    </div>
  );
};

export default HomePage;
