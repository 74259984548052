import * as React from "react";
import { useState, useContext } from "react";
import { motion } from "framer-motion";
import { wrap } from "popmotion";
import Image from "next/image";
import ThemeContext from "../../contexts/Main";
import { Dots } from "../Locations/SliderContent";
import { carouselData } from "./carousel-data";
import Link from "next/link";
import Icon from "../Icons";
import { useRouter } from "next/router";

const variants = {
  enter: () => {
    return {
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: () => {
    return {
      zIndex: 0,
      opacity: 0,
    };
  },
};

const STRIPE_CLU3_ACCESS_TOKEN = "f495070cabef89be5a8d328457357c0a";

const MintCarousel = () => {
  const { theme } = useContext(ThemeContext);
  const router = useRouter();
  const [[page, direction], setPage] = useState([0, 0]);
  const { query } = router;
  const { access } = query;
  const stripeUser = access === STRIPE_CLU3_ACCESS_TOKEN;

  const index = wrap(0, carouselData.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div className="relative min-h-[100%] lg:min-h-[auto] sm:min-h-[auto]">
      {stripeUser ? (
        <h2 className="fnt-bold text-center py-10 light-green-text sm:text-left sm:p-4 sm:pl-[14px] x-spacing">
          Own YOUR <br className="hidden sm:block" />
          MEMBERSHIP
        </h2>
      ) : (
        <h2 className="fnt-bold text-center py-10 light-green-text sm:text-left sm:p-4 sm:pl-[14px] x-spacing">
          MINT YOUR <br className="hidden sm:block" />
          MEMBERSHIP
        </h2>
      )}
      <motion.div
        key={page}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          opacity: { duration: 0.2 },
        }}
      >
        <div className="mint-membership-section">
          <div className="mobile-item-mg mx-5">
            <div className="flex md:min-h-[135px] justify-center items-center">
              <h3 className="item-title fnt-medium green-text md:w-2/4">
                {carouselData[index].title}
              </h3>
              <div className="flex flex-col items-center">
                <div className="item-img relative h-[146px] w-[84px] top-[-20px]">
                  <Image
                    src={carouselData[index].img}
                    layout="fill"
                    alt="membership-image"
                    placeholder="blur"
                  />
                </div>
                <div className="w-[45px] relative top-[-25px] h-[11px]">
                  <Image
                    src="/images/membership/card/shadow.png"
                    alt="shadow"
                    layout="fill"
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="item-description lg:mb-10 sm:mb-8 md:px-8 md:pt-6 text-center">
            {carouselData[index].description}
          </p>
          <Link
            href={{
              pathname: carouselData[index].ctaUrl,
              query: router.query,
            }}
          >
            <a className="capitalize hidden md:block green-text underline text-center">
              Learn more
            </a>
          </Link>
        </div>
        <Dots
          content={carouselData}
          active={index}
          color={theme.lightGreen}
          className="absolute inset-x-0"
          paginate={paginate}
          index={index}
        />
      </motion.div>
      <div className="next" onClick={() => paginate(1)}>
        <Icon icon="right-arrow" size={25} color={theme.bgPrimary} />
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        <Icon icon="left-arrow" size={25} color={theme.bgPrimary} />
      </div>
      <style jsx>{`
        .next,
        .prev {
          top: calc(40%);
          position: absolute;
          background: ${theme.lightGreen};
          color: ${theme.bgPrimary};
          border-radius: 30px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          z-index: 2;
        }

        .next {
          right: 10px;
        }

        .prev {
          left: 10px;
        }
        h2 {
          font-size: ${theme.sizes.boldTitle.h4.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h4.lineHeight - 4}px;
        }
        .mobile-item-mg .item-title {
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          color: ${theme.lightGreen};
        }
        .item-description {
          font-size: ${theme.sizes.content.p7.fontSize}px;
          line-height: ${theme.sizes.content.p7.lineHeight}px;
        }
        .item-title {
          font-size: ${theme.sizes.mediumTitle.h7.fontSize - 2}px;
          line-height: ${theme.sizes.mediumTitle.h7.lineHeight}px;
        }
      `}</style>
    </div>
  );
};

export default MintCarousel;
