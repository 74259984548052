import Link from "next/link";
import { useContext, useState } from "react";
import ThemeContext from "../../../contexts/Main";
import Icon from "../../Icons";
import { footerContactDetails, footerHelpfullLinks } from "./footer-data";
import ContactModel from "./contact-model";
import { useRouter } from "next/router";

type FooterProps = {};

const Footer: React.FC<FooterProps> = ({ ...props }) => {
  const { theme } = useContext(ThemeContext);
  const [showModel, setShowModel] = useState(false);
  const router = useRouter();
  const { query } = router;
  const { utm_campaign = null } = query;

  const toggleModel = () => {
    setShowModel(!showModel);
  };

  const getPathWithSectionNavigation = (pathName: string) => {
    let section = pathName.split("#");

    let queryParams = [];
    for (let key in router.query) {
      queryParams.push(`${key}=${router.query[key]}`);
    }

    if (queryParams.length) {
      return `${section[0]}?${queryParams.join("&")}#${section[1]}`;
    }
    return pathName;
  };

  const getUrl = (pathName: string) => {
    if (pathName.includes("#")) {
      return getPathWithSectionNavigation(pathName);
    }
    return {
      pathname: pathName,
      query: { ...router.query },
    };
  };

  return (
    <div className="footer flex flex-wrap">
      <div className="left w-2/4 xl:w-full">
        <Link href={getUrl(utm_campaign ? "/main" : "/")}>
          <a className="logo flex justify-start items-center flex-grow">
            <Icon
              icon="club3-small-logo"
              className="logo-icon"
              size={120}
              color={theme.primary}
            ></Icon>
          </a>
        </Link>
        <div className="fnt-medium description">
          Club 3 is a hub, a home, a community - tailored to the artists,
          creators, rule-breakers, leaders, and thinkers of tomorrow.
        </div>
        {footerContactDetails.map((detail) => (
          <div className="contact flex items-center" key={detail.name}>
            <span className="icon flex justify-center items-center">
              <Icon icon={detail.icon} size={17} color={theme.bgPrimary}></Icon>
            </span>
            <span className="text">
              <div className="name green-text">{detail.name}</div>
              <div className="detail">{detail.value}</div>
            </span>
          </div>
        ))}
      </div>
      <div className="right w-2/4 xl:w-full">
        <div className="helpfull-links flex md:flex-wrap pt-[70px] xl:pt-0">
          {footerHelpfullLinks.map((section) => (
            <div
              key={section.heading}
              className="section flex flex-col md:w-full md:mt-6"
            >
              <div className="fnt-semiMedium heading">{section.heading}</div>
              {section.links.map((link) => (
                <Link
                  key={link.name}
                  data-ctalink={link.isCta}
                  href={getUrl(link.href)}
                  passHref
                >
                  <a
                    className="link"
                    target={link.target ? link.target : "_self"}
                  >
                    {link.name}
                  </a>
                </Link>
              ))}
              {section.heading === "Support" && (
                <a className="link cursor-pointer" onClick={toggleModel}>
                  Contact us
                </a>
              )}
            </div>
          ))}
        </div>
        <div className="google_policy pl-3 pt-4">
          <p>
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">
              &nbsp;Privacy Policy&nbsp;
            </a>
            and
            <a href="https://policies.google.com/terms">
              &nbsp;Terms of Service&nbsp;
            </a>
            apply.
          </p>
        </div>
        <ContactModel
          showModel={showModel}
          toggleModel={toggleModel}
          theme={theme}
        />
        {/* <div className="social flex items-center flex-wrap">
          {footerSocialLinks.map((link) => (
            <Link href={link.href} key={link.name}>
              <a
                className="link flex flex-col justify-end items-start w-1/4 h-28 md:w-6/12 md:mb-6 sm:w-9/12"
                style={{ backgroundColor: link.backgroundColor }}
              >
                <div className="flex justify-between items-center w-full">
                  <div>
                    <div>Follow us on</div>
                    <div>{link.name}</div>
                  </div>
                  <Icon
                    icon={link.icon}
                    size={20}
                    color={theme.fontColorLight}
                  ></Icon>
                </div>
              </a>
            </Link>
          ))}
        </div> */}
      </div>
      <style jsx>{`
        .footer .left {
          padding-right: 22%;
        }
        .footer :global(.logo-icon) {
          margin-right: 17px;
        }
        .description {
          letter-spacing: -1px;
          margin: 29px 0px 32px;
        }
        .contact {
          margin-bottom: 20px;
        }
        .contact .icon {
          min-width: 48px;
          min-height: 48px;
          border-radius: 50%;
          margin-right: 12px;
        }
        .helpfull-links .section {
          min-width: 33%;
          padding-left: 10px;
        }
        .helpfull-links .section .heading {
          margin-bottom: 24px;
        }
        .helpfull-links .link {
          margin-bottom: 20px;
        }
        .social {
          margin-top: 30px;
          align-items: center;
        }
        .social .link {
          padding: 13px 22px;
          border-radius: 6px;
          margin-right: 30px;
        }
        .google_policy {
          font-size: 10px;
        }
        .google_policy a {
          color: ${theme.green};
        }
      `}</style>
      <style jsx>{`
        .footer {
          background: ${theme.creamColor};
          padding: ${theme.spacing.xl.x}px ${theme.spacing.xxl.y - 5}px;
        }
        .description,
        .contact .text .detail {
          font-size: ${theme.sizes.content.p5.fontSize}px;
          line-height: ${theme.sizes.content.p5.lineHeight}px;
        }
        .contact .text .name {
          font-size: ${theme.sizes.small.s1.fontSize}px;
          line-height: ${theme.sizes.small.s1.lineHeight}px;
        }
        .contact .icon {
          background-color: ${theme.lightGreen};
        }
        .helpfull-links .section .heading {
          font-size: ${theme.sizes.content.p4.fontSize}px;
          line-height: ${theme.sizes.content.p4.lineHeight}px;
          color: ${theme.green};
        }
        .helpfull-links .link {
          color: ${theme.footerHelfulLinkColor};
        }
        .social .link {
          color: ${theme.fontColorLight};
        }
        @media screen and (max-width: ${theme.breakpoints.xl}px) {
          .footer {
            padding: ${theme.spacing.xl.y / 2}px ${theme.spacing.xl.x}px;
          }
          .footer .left {
            margin-bottom: ${theme.spacing.lg.x}px;
          }
          .footer .left,
          .footer .right {
            min-height: auto;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .footer {
            padding: ${theme.spacing.md.y / 2}px ${theme.spacing.md.x}px;
          }
          .footer .left {
            margin-bottom: 0px;
          }
          .helpfull-links .link {
            margin-bottom: 10px;
          }
          .helpfull-links .section .heading {
            margin-bottom: 15px;
          }
        }
      `}</style>
    </div>
  );
};

export default Footer;
