import theme from "../../theme";
import IcoMoon from "react-icomoon";
import matterSet from "./club3.selection.json";

export const icons = [
  "printer",
  "location",
  "sms",
  "phone",
  "fire",
  "atom",
  "video",
  "club3-logo",
  "play",
  "plus",
  "minus",
  "hamburger",
  "cross",
  "left-arrow",
  "right-arrow",
  "checkmark",
  "facebook",
  "twitter",
  "instagram",
  "link",
  "plant",
  "globe",
  "hundered",
  "currency",
  "nft",
  "wallet",
  "wallet2",
  "membership",
  "star-plant",
  "checklist",
  "glass",
  "nft2",
  "facebook2",
  "arrow-up",
  "arrow-down",
  "club3-la-logo",
  "club3-la-green-logo",
  "cocktail",
  "fingerprint",
  "nft-1",
  "arrow-left2",
  "metamask",
  "wallet-connect",
  "wallet-1",
  "clock",
  "file-text2",
  "tick-icon",
  "club3-small-logo",
  "discord",
] as const;

export type IconType = typeof icons[number];

type IconProps = {
  [name: string]: any;
  icon: IconType;
  color?: string;
  size?: string | number;
  className?: string;
  style?: React.CSSProperties;
};

const Icon: React.FC<IconProps> = ({ ...props }) => {
  return <IcoMoon iconSet={matterSet} {...props} />;
};

Icon.defaultProps = {
  color: theme.fontColorPrimary,
};

export default Icon;
