import React from "react";
import Layout from "../components/Layout";
import Landing from "../components/Landing";
import Head from "next/head";

export default function Docs() {
  return (
    <Layout className="landing">
      <Head>
        <title>Club 3 Members - An Exclusive Club for Web3 Community</title>
        <meta
          name="description"
          content="A private members-only club built for the Web3 community. Connect, build, play, collaborate, and unwind with the best-in-class design and hospitality you deserve."
        />
      </Head>
      <Landing />
    </Layout>
  );
}
