const colors = {
  white: "#FFFFFF",
  black: "#000000",
  blackAlt: "#171717",
  lightBlackBtn: "#2E363E",
  lightBlackText: "#141414",
  lightestBlue: "#F7FAFF",
  lightestGrey: "#434B53",
  lightGreyAlt: "#F6FAFF",
  mediumBlue: "#E3EBFD",
  lightGrey: "#8E8E8E",
  lightWhite: "#F2F6FA",
  skyBlue: "#5D9CFB",
  ligthRed: "#FF8777",
  darkSkyBlue: "#1B76F2",
  darkestSkyeBlue: "#2B4A87",
  lightGreyBorder: "#7E868E",
  fontLightGrey: "#C6C6C6",
  silver: "#9A9EA6",
  romansilver: "#E2EAF2",
  darkBlueGray: "#6F6C90",
  classicMatteGrey: "#6D6D6D",
  red: "#FF0000",
  lighterBlack: '#262626',
};

// These breakpoints are same as breakpoints defined in tailwind.config.js
// If any edits are made to these breakpoints please change the same in tailwind.config.js to maintain consistency
// These breakpoints are defined with keeping max-width media queries in mind

const breakpoints = {
  sm: 474,
  md: 767,
  lg: 1023,
  xl: 1279,
  xxl: 1439,
};

// These margins are defined for the outer container, so everything looks aligned and follows a set rule
const spacing = {
  xxl: {
    y: 150,
    x: 80,
  },
  xl: {
    y: 120,
    x: 60,
  },
  lg: {
    y: 100,
    x: 40,
  },
  md: {
    y: 80,
    x: 32,
  },
  sm: {
    y: 80,
    x: 16,
  },
};

const sizes = {
  //button sizes
  button: {
    large: {
      fontSize: 18,
      lineHeight: 27,
      padding: "30px 50px",
      minHeight: "88px",
    },
    medium: {
      fontSize: 18,
      lineHeight: 27,
      padding: "20.5px 50px",
      minHeight: "69px",
    },
    small: {
      fontSize: 11,
      lineHeight: 16.5,
      padding: "22px 28px 22px 18px",
      minHeight: "69px",
    },
  },
  // Title sizes for Text with font-weight:700(Bold)
  boldTitle: {
    h1: {
      fontSize: 160,
      lineHeight: 195,
    },
    h2: {
      fontSize: 72,
      lineHeight: 87,
    },
    h3: {
      fontSize: 48,
      lineHeight: 60,
    },
    h4: {
      fontSize: 24,
      lineHeight: 36,
    },
    h5: {
      fontSize: 56,
      lineHeight: 68,
    },
    h6: {
      fontSize: 40,
      lineHeight: 52,
    },
  },
  // Title sizes for Text with font-weight:500(Medium)
  mediumTitle: {
    h1: {
      fontSize: 170,
      lineHeight: 255,
    },
    h2: {
      fontSize: 96,
      lineHeight: 117,
    },
    h3: {
      fontSize: 56,
      lineHeight: 84,
    },
    h4: {
      fontSize: 48,
      lineHeight: 72,
    },
    h5: {
      fontSize: 24,
      lineHeight: 34,
    },
    h6: {
      fontSize: 24,
      lineHeight: 30,
    },
    h7: {
      fontSize: 22,
      lineHeight: 28,
    },
  },
  // Title sizes for Text with font-weight:400(Regular)
  regularTitle: {
    h1: { fontSize: 56, lineHeight: 80 },
    h2: { fontSize: 36, lineHeight: 50 },
    h3: {
      fontSize: 32,
      lineHeight: 45,
    },
    h4: {
      fontSize: 32,
      lineHeight: 48,
    },
  },
  content: {
    p1: {
      fontSize: 24,
      lineHeight: 30,
    },
    p2: {
      fontSize: 21,
      lineHeight: 30,
    },
    p3: {
      fontSize: 18,
      lineHeight: 30,
    },
    p4: {
      fontSize: 18,
      lineHeight: 22,
    },
    p5: { fontSize: 16, lineHeight: 19.5 },
    p6: {
      fontSize: 15,
      lineHeight: 25,
    },
    p7: {
      fontSize: 14,
      lineHeight: 21,
    },
    p8: {
      fontSize: 14,
      lineHeight: 17,
    },
  },
  small: {
    s1: {
      fontSize: 12,
      lineHeight: 15,
    },
  },
};

// There are also classes in Layout.ts file to set font family and font weight
const fonts = {
  // This is default
  regular: {
    name: "Montserrat-Regular",
    weight: 400,
  },
  //className:"fnt-medium"
  medium: {
    name: "Montserrat-Medium",
    weight: 500,
  },
  //className:"fnt-bold"
  bold: {
    name: "Montserrat-Bold",
    weight: 700,
  },
};

const buttons = {
  base: {
    borderWidth: "1px",
    borderRadius: "8px",
  },
  primaryCta: {
    background: colors.skyBlue,
    color: colors.white,
    borderColor: colors.skyBlue,
    font: fonts.medium,
  },
  secondaryCta: {
    background: "transparent",
    color: colors.blackAlt,
    borderColor: colors.skyBlue,
    font: fonts.medium,
  },
  default: {
    background: colors.lightWhite,
    color: colors.lightBlackBtn,
    borderColor: colors.lightWhite,
    font: fonts.regular,
  },
};

export const theme = {
  bgPrimary: colors.white,
  bgSecondary: colors.lightestBlue,
  bgAlt: colors.mediumBlue,
  fontColorPrimary: colors.black,
  fontColorSecondary: colors.lightestGrey,
  fontColorLight: colors.white,
  fontLightDark: colors.classicMatteGrey,
  primary: colors.skyBlue,
  btnSecondary: colors.lightWhite,
  instaBg: colors.ligthRed,
  faceBookBg: colors.darkSkyBlue,
  discordBg: colors.darkestSkyeBlue,
  borderColor: colors.lightGreyBorder,
  footerFontColor: colors.fontLightGrey,
  footerIconColor: colors.lightGrey,
  footerHelfulLinkColor: colors.lightBlackText,
  grayText: colors.silver,
  silverText: colors.romansilver,
  heroBg: colors.lightGreyAlt,
  lightDarkColor: colors.lightBlackBtn,
  accordionText: colors.darkBlueGray,
  crossRed: colors.red,
  lighterBlack: colors.lighterBlack,
  fonts,
  sizes,
  buttons,
  breakpoints,
  spacing,
};

export default theme;
