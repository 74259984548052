import React, { ReactNode, useContext, useEffect, useState } from "react";
import ThemeContext from "../contexts/Main";
import clsx from "clsx";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { useCycle } from "framer-motion";
import SideMenu from "./Common/SideMenu";

type Props = {
  children: ReactNode;
  className: string;
};

const Layout: React.FC<Props> = ({ children, className }) => {
  const { theme } = useContext(ThemeContext);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  return (
    <div className="layout">
      <Header toggleSideMenu={toggleSideMenu} />
      <SideMenu open={sideMenuOpen} toggleSideMenu={toggleSideMenu} />
      <div className={clsx("page-content", className)}>{children}</div>
      <Footer />
      <style jsx global>{`
        html,
        button,
        input,
        select,
        textarea,
        * {
          font-style: regular;
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        body {
          margin: 0px;
          font-size: 16px;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0px;
        }

        input,
        textarea {
          font-size: 16px;
        }

        .layout {
          background: ${theme.bgPrimary};
          max-width: 1920px;
          min-width: 375px;
          margin: 0 auto;
        }

        .page-content {
          min-height: calc(100vh - 701px);
        }
        .light-green-text {
          color: ${theme.lightGreen}
        }
        .green-text {
          color: ${theme.green};
        }
        .neon-text {
          color: ${theme.bgPrimary};
        }
        .white-text {
          color: ${theme.bgPrimary};
        }
      `}</style>
      <style jsx global>{`
        html,
        button,
        input,
        select,
        textarea {
          font-family: ${theme.fonts.regular.name};
          font-weight: ${theme.fonts.regular.weight};
        }

        body {
          background: ${theme.bgPrimary};
          color: ${theme.fontColorPrimary};
        }

        li {
          list-style-type: none;
        }

        .layout {
          background: ${theme.bgPrimary};
        }

        .fnt-medium {
          font-family: ${theme.fonts.medium.name};
          font-weight: ${theme.fonts.medium.weight};
        }

        .fnt-bold {
          font-family: ${theme.fonts.bold.name};
          font-weight: ${theme.fonts.bold.weight};
        }

        .fnt-semiMedium {
          font-family: ${theme.fonts.semiMedium.name};
          font-weight: ${theme.fonts.semiMedium.weight};
        }

        //use the "spacing" to get a default space around the components inside layout
        .layout :global(.y-spacing) {
          margin-top: ${theme.spacing.xxl.y}px;
          margin-bottom: ${theme.spacing.xxl.y}px;
        }

        :global(.round-image) {
          border-radius: 20px;
        }

        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .layout :global(.y-spacing) {
            margin-top: ${theme.spacing.lg.x + 8}px;
            margin-bottom: ${theme.spacing.lg.x + 8}px;
          }
          :global(.round-image) {
            border-radius: 0px;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .layout :global(.y-spacing) {
            margin-top: ${theme.spacing.lg.x + 8}px;
            margin-bottom: ${theme.spacing.lg.x + 8}px;
          }
        }
        .layout :global(.x-spacing) {
          margin-left: ${theme.spacing.xxl.x}px;
          margin-right: ${theme.spacing.xxl.x}px;
        }

        @media screen and (max-width: ${theme.breakpoints.xl}px) {
          .layout :global(.x-spacing) {
            margin-left: ${theme.spacing.xl.x}px;
            margin-right: ${theme.spacing.xl.x}px;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .layout :global(.x-spacing) {
            margin-left: ${theme.spacing.lg.x}px;
            margin-right: ${theme.spacing.lg.x}px;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .layout :global(.x-spacing) {
            margin-left: ${theme.spacing.md.x}px;
            margin-right: ${theme.spacing.md.x}px;
          }
          .layout :global(.x-spacing.x-spacing-0) {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
      `}</style>
      <style jsx global>{`
        :global(body .cdx-notifies) {
          top: 150px;
          left: 50%;
          transform: translateX(-50%);
          font-family: ${theme.fonts.regular.name};
          font-weight: ${theme.fonts.regular.weight};
        }

        :global(.cdx-notify.cdx-notify--error) {
          background: ${theme.bgPrimary} !important;
          background-color: ${theme.bgPrimary} !important;
          padding: 20px;
          width: 270px;
        }
        :global(.cdx-notify.cdx-notify--error a) {
          color: #f6851a;
          font-family: ${theme.fonts.bold.name};
          font-weight: ${theme.fonts.bold.weight};
        }
        :global(.cdx-notify.cdx-notify--error strong) {
          font-family: ${theme.fonts.bold.name};
          font-weight: ${theme.fonts.bold.weight};
        }
        :global(.cdx-notify.cdx-notify--error .cdx-notify__cross) {
          top: 10px;
          right: 15px;
          left: auto;
        }
      `}</style>
    </div>
  );
};

Layout.defaultProps = {};

export default Layout;
