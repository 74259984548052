import React from "react";
import Head from "next/head";
import HomePage from "../components/Homepage";

export default function Docs() {
  return (
    <>
      <Head>
        <title>Club 3 - Home</title>
      </Head>
      <HomePage />
    </>
  );
}
