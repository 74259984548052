import { useContext } from "react";
import ThemeContext from "../contexts/Main";
import Marquee from "./Marquee";

const ComingSoonBanner: React.FC = ({ ...props }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="coming-soon fnt-medium">
      <p className="cs-subtitle light-green-text uppercase text-center">Locations Coming Soon</p>
      <Marquee />
      <style jsx>{`
        .cs-subtitle {
          font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h3.lineHeight + 5}px;
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .cs-subtitle {
            font-size: ${theme.sizes.content.p1.fontSize}px;
            line-height: ${theme.sizes.content.p1.lineHeight + 2}px;
          }
        }
      `}</style>
    </div>
  );
};

export default ComingSoonBanner;
