import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useContext } from "react";
import ThemeContext from "../../contexts/Main";
import Button, { ButtonKind, ButtonSize } from "../Button";
import Icon from "../Icons";
import { useRouter } from "next/router";

type sideMenuProps = {
  open: boolean;
  toggleSideMenu: () => void;
};
const STRIPE_CLU3_ACCESS_TOKEN = "f495070cabef89be5a8d328457357c0a";

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.025,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

const INIT_STYLES = {
  width: 0,
  minWidth: 0,
};

const FINAL_STYLES = {
  width: "100vw",
  minWidth: "375px",
};

const SideMenu: React.FC<sideMenuProps> = ({
  open,
  toggleSideMenu,
  ...props
}) => {
  const { theme } = useContext(ThemeContext);
  const router = useRouter();
  const { pathname, query } = router;
  const { access, utm_campaign } = query;
  const stripeUser = access === STRIPE_CLU3_ACCESS_TOKEN;
  const getUrl = (pathName: string) => {
    return {
      pathname: pathName,
      query: { ...router.query },
    };
  };

  return (
    <div className="side-menu">
      <AnimatePresence>
        {open && (
          <motion.aside
            className="sidebar"
            initial={INIT_STYLES}
            animate={FINAL_STYLES}
            exit={{
              ...INIT_STYLES,
              transition: { delay: 0.3, duration: 0.7 },
            }}
          >
            <div className="sb-container">
              <motion.div
                className="sidebar-header flex justify-between w-full"
                initial="closed"
                animate="open"
                exit="closed"
                variants={itemVariants}
              >
                <motion.div
                  onClick={toggleSideMenu}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={itemVariants}
                  className="sb-close"
                >
                  <Link href={getUrl(utm_campaign ? "/main" : "/")}>
                    <a
                      className="logo flex justify-center items-center w-max flex-grow xl:flex-grow-0"
                      onClick={toggleSideMenu}
                    >
                      <Icon
                        icon="club3-logo"
                        className="logo-icon"
                        size={100}
                        color={theme.primary}
                      ></Icon>
                    </a>
                  </Link>
                  <div className="sidebar-descp pt-5 fnt-medium">
                    Club 3 is for a hub, a home, community - tailored to the
                    artists, creators, rule-breakers, leaders, and thinkers of
                    tomorrow.
                  </div>
                </motion.div>
                <motion.div
                  onClick={toggleSideMenu}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={itemVariants}
                  className="sb-close"
                >
                  <Icon
                    icon="cross"
                    className="cursor-pointer"
                    size={25}
                  ></Icon>
                </motion.div>
              </motion.div>

              <motion.div
                className="sidebar-body flex flex-col"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <Link href={getUrl(utm_campaign ? "/main" : "/")}>
                  <motion.a
                    variants={itemVariants}
                    onClick={toggleSideMenu}
                    className={`my-4 ${
                      (pathname === "/" || pathname === "/main") && "green-text"
                    }`}
                  >
                    Home
                  </motion.a>
                </Link>

                <Link href={getUrl("/join")}>
                  <motion.a
                    variants={itemVariants}
                    onClick={toggleSideMenu}
                    className={`my-4 ${pathname === "/join" && "green-text"}`}
                  >
                    Join
                  </motion.a>
                </Link>

                {!stripeUser && (
                  <Link href={getUrl("/how-it-works")}>
                    <motion.a
                      variants={itemVariants}
                      onClick={toggleSideMenu}
                      className={`my-4 ${
                        pathname === "/how-it-works" && "green-text"
                      }`}
                    >
                      How it Works
                    </motion.a>
                  </Link>
                )}

                <Link href={getUrl("/memberships")}>
                  <motion.a
                    variants={itemVariants}
                    onClick={toggleSideMenu}
                    className={`my-4 ${
                      pathname === "/memberships" && "green-text"
                    }`}
                  >
                    Membership
                  </motion.a>
                </Link>
                <Link href="https://blog.metahollywood.io/">
                  <a className="my-4">Blog</a>
                </Link>
                <Link href={getUrl("/locations")}>
                  <motion.a
                    variants={itemVariants}
                    onClick={toggleSideMenu}
                    className={`my-4 ${
                      pathname === "/locations" && "green-text"
                    }`}
                  >
                    Location
                  </motion.a>
                </Link>

                <Link href={getUrl("/about")}>
                  <motion.a
                    variants={itemVariants}
                    onClick={toggleSideMenu}
                    className={`my-4 ${pathname === "/about" && "green-text"}`}
                  >
                    About
                  </motion.a>
                </Link>

                {/* <motion.button variants={itemVariants} onClick={toggleSideMenu}>
                  <Button
                    kind={ButtonKind.primaryCta}
                    size={ButtonSize.medium}
                    cta={true}
                    className="my-4 hidden md:block"
                  >
                    Connect Wallet
                  </Button>
                </motion.button> */}

                {/* <motion.ul className="flex pt-6">
                  <li>
                    <Link href="/">
                      <a>
                        <Icon icon="facebook2" size={30} />
                      </a>
                    </Link>
                  </li>
                  <li className="ml-8">
                    <Link href="/">
                      <a>
                        <Icon icon="instagram" size={30} color={theme.lightGreen} />
                      </a>
                    </Link>
                  </li>
                  <li className="ml-8">
                    <Link href="/">
                      <a>
                        <Icon icon="twitter" size={30} color={theme.lightGreen} />
                      </a>
                    </Link>
                  </li>
                </motion.ul> */}

                {/* <motion.ul className="pt-6">
                  <li className="sidebar-descp">706 Campfire Ave. Meriden, CT 06450</li>
                  <li className="sidebar-descp pt-2">Tel&nbsp;&nbsp;: 310-437-2766&nbsp;&nbsp;&nbsp;&nbsp;Fax&nbsp;&nbsp;: +1-212-9876543</li>
                  <li className="sidebar-descp pt-2">Email&nbsp;&nbsp;: unreal@outlook.com</li>
                </motion.ul> */}
              </motion.div>
            </div>
          </motion.aside>
        )}
      </AnimatePresence>
      <style jsx>{`
        .side-menu {
          height: 100vh;
          position: fixed;
          z-index: 120;
          left: 0px;
          top: 0px;
        }
        .logo :global(.logo-icon) {
          margin-right: 17px;
        }
      `}</style>
      <style jsx>{`
        .side-menu {
          background-color: ${theme.bgPrimary};
          font-size: ${theme.sizes.content.p6.fontSize}px;
          line-height: ${theme.sizes.content.p6.lineHeight}px;
        }
        .side-menu :global(.sidebar-header) {
          padding: 16px ${theme.spacing.lg.x}px;
        }
        .side-menu :global(.sidebar-body) {
          padding: 5px ${theme.spacing.lg.x}px;
        }
        .sidebar-descp {
          font-size: ${theme.sizes.small.s1.fontSize - 2}px;
          line-height: ${theme.sizes.small.s1.lineHeight}px;
        }
      `}</style>
    </div>
  );
};

export default SideMenu;
