import React, { useState, useRef, useContext, useEffect } from "react";
import Button, { ButtonKind, ButtonSize } from "../Button";
import ReCAPTCHA from "react-google-recaptcha";
import ThemeContext from "../../contexts/Main";
import { validateEmail } from "../../lib/common";
import { ethers } from "ethers";
import { useRouter } from "next/router";
import ConnectWalletModel from "../Wallet/ConnectWalletModel";

const STRIPE_CLU3_ACCESS_TOKEN = "f495070cabef89be5a8d328457357c0a";

export default function GetInTouch() {
  const { theme } = useContext(ThemeContext);
  const [form, setForm] = useState({
    name: "",
    email: "",
    surname: "",
    location: "",
    other: "",
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const reRef = useRef<ReCAPTCHA>();
  const { query } = useRouter();
  const { utm_campaign = null } = query;
  const { access } = query;
  const stripeUser = access === STRIPE_CLU3_ACCESS_TOKEN;

  const [showModel, setShowModel] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const handleInputChange = (inputValue: string, inputName: string) => {
    setForm({
      ...form,
      [inputName]: inputValue,
    });
  };

  const validateForm = (inputs: any) => {
    const errors: any = {};
    if (!inputs.email.trim()) {
      errors.email = "This is required field";
    } else if (!validateEmail(inputs.email)) {
      errors.email = "Please enter valid email";
    }
    if (!inputs.name.trim()) {
      errors.name = "This is required field";
    }
    if (!inputs.location.trim()) {
      errors.location = "This is required field";
    }
    if (inputs.location === "Other" && !inputs.other.trim()) {
      errors.other = "This is required field";
    }
    return errors;
  };

  useEffect(() => {
    if (!window.ethereum) {
      return;
    }
    const accountWasChanged = (accounts: any) => {
      setWalletAddress(accounts[0]);
    };
    window.ethereum.on("accountsChanged", accountWasChanged);
    return () => {
      if (window.ethereum?.off) {
        window.ethereum.off("accountsChanged", accountWasChanged);
      }
    };
  }, []);

  const handleRegistration = async (account: string) => {
    if (!utm_campaign) {
      setFormErrors({});
      const errors = validateForm(form);
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
    }
    setLoading(true);
    const token = await reRef?.current?.executeAsync();
    reRef?.current?.reset();
    const wallet = utm_campaign ? account : "";
    const utmCampaign = utm_campaign ? utm_campaign : "";
    const formBody = { ...form, wallet, utmCampaign, token: token };
    const response = await fetch("/api/membership", {
      method: "POST",
      body: JSON.stringify(formBody),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const { data, error } = await response.json();
    await new Promise((r) => setTimeout(r, 800));
    setLoading(false);
    if (error) {
      alert(`Error: ${error}`);
    } else {
      setForm({
        name: "",
        email: "",
        surname: "",
        location: "",
        other: "",
      });
      alert("Submission received");
    }
  };

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    setFormErrors({});
    const errors = validateForm(form);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      if (!walletAddress) {
        setShowModel(true);
      } else {
        handleRegistration(walletAddress);
      }
    }
  };

  const closeModel = () => {
    setShowModel(false);
  };

  return (
    <div
      id="register"
      className="get-in-touch flex justify-center pb-20 pt-10 md:pt-0"
    >
      <div className="p-[30px]">
        <h3 className="fnt-bold text-center light-green-text reserve-heading">
          Reserve Now for Early Access
        </h3>
        {stripeUser ? (
          <div className="reserve-descp fnt-medium text-center w-[897px] pt-8 green-text lg:w-full md:pt-5">
            Join the waitlist for first access to your Club 3 Membership
          </div>
        ) : (
          <div className="reserve-descp fnt-medium text-center w-[897px] pt-8 green-text lg:w-full md:pt-5">
            Join the waitlist for first access to mint your Club 3 Membership
          </div>
        )}
        <ReCAPTCHA
          sitekey={String(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY)}
          size="invisible"
          ref={reRef as React.RefObject<ReCAPTCHA>}
        />
        <form className="pt-14 md:pt-5">
          <div className="form-group mb-6">
            <input
              type="text"
              className={`form-control w-full custom-input ${
                formErrors?.name ? "red-border" : ""
              }`}
              placeholder="First Name"
              value={form.name}
              onChange={(e: any) => handleInputChange(e.target.value, "name")}
              required
            />
            {formErrors?.name && (
              <div className="text-sm mt-1 red-text">{formErrors.name}</div>
            )}
          </div>
          <div className="form-group mb-6">
            <input
              type="text"
              className={`form-control w-full custom-input ${
                formErrors?.surname ? "red-border" : ""
              }`}
              placeholder="Last Name"
              value={form.surname}
              onChange={(e: any) =>
                handleInputChange(e.target.value, "surname")
              }
            />
          </div>
          <div className="form-group mb-6">
            <input
              type="email"
              className={`form-control w-full custom-input ${
                formErrors?.email ? "red-border" : ""
              }`}
              placeholder="Email"
              value={form.email}
              onChange={(e: any) => handleInputChange(e.target.value, "email")}
              required
            />
            {formErrors?.email && (
              <div className="text-sm mt-1 red-text">{formErrors.email}</div>
            )}
          </div>
          <div className="form-group mb-6">
            <div className="relative">
              <select
                className={`block appearance-none w-full custom-input ${
                  formErrors?.location ? "red-border" : ""
                }`}
                value={form.location}
                onChange={(e: any) =>
                  handleInputChange(e.target.value, "location")
                }
                required
              >
                <option value={""} disabled>
                  Location
                </option>
                <option>New York</option>
                <option>Los Angeles</option>
                <option>London</option>
                <option>Paris</option>
                <option>Tokyo</option>
                <option>Hong Kong</option>
                <option>Miami</option>
                <option>Toronto</option>
                <option>Other</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {formErrors?.location && (
              <div className="text-sm mt-1 red-text">{formErrors.location}</div>
            )}
          </div>
          {form.location === "Other" && (
            <div className="form-group mb-6">
              <input
                type="text"
                className={`form-control w-full custom-input ${
                  formErrors?.other ? "red-border" : ""
                }`}
                placeholder="Enter Location"
                value={form.other}
                onChange={(e: any) =>
                  handleInputChange(e.target.value, "other")
                }
                required
              />
              {formErrors?.other && (
                <div className="text-sm mt-1 red-text">{formErrors.other}</div>
              )}
            </div>
          )}
          <Button
            kind={ButtonKind.primaryCta}
            size={ButtonSize.large}
            cta={true}
            className="min-w-[100%] custom-button"
            showLoader={isLoading}
            type="button"
            disabled={isLoading}
            onClick={
              utm_campaign && !stripeUser
                ? handleFormSubmit
                : () => handleRegistration("")
            }
          >
            {utm_campaign && !stripeUser
              ? "Connect wallet and submit"
              : "Submit"}
          </Button>
          <div className="copyright text-center pt-10">
            <h2 className="copyright_text">
              Copyright © {new Date().getFullYear()} Club 3.
            </h2>
            <div className="copyright_links">
              <p className="copyright_links_text vertical_border">
                <a
                  href="/docs/Club3_User_Terms_and_Conditions.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms &amp; Conditions
                </a>
              </p>

              <p className="copyright_links_text vertical_border">
                <a
                  href="/docs/Club3_Privacy_Policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </p>
            </div>
          </div>
          <div className="google_policy text-center pt-2">
            <p>
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">
                &nbsp;Privacy Policy&nbsp;
              </a>
              and
              <a href="https://policies.google.com/terms">
                &nbsp;Terms of Service&nbsp;
              </a>
              apply.
            </p>
          </div>
        </form>
      </div>
      <ConnectWalletModel
        open={showModel}
        closeModel={closeModel}
        setWalletAddress={setWalletAddress}
        handleRegistration={handleRegistration}
      />
      <style jsx global>{`
        .reserve-heading {
          font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h3.lineHeight}px;
        }
        .reserve-descp {
          font-size: ${theme.sizes.boldTitle.h6.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h6.lineHeight}px;
        }
        .custom-input {
          background: ${theme.lighterGreen};
          padding: 25px 0px 25px 24px;
          border-radius: 20px;
        }
        .custom-input::placeholder {
          color: ${theme.fontColorPrimary};
        }
        .custom-input:focus {
          outline: none !important;
          border: 1px solid ${theme.green};
        }
        .get-in-touch .custom-button {
          background: ${theme.green};
          border-radius: 50px;
          padding: 25px;
        }
        .red-text {
          color: red;
        }
        .red-border {
          border: 1px solid red;
        }
        .copyright_text {
          font-weight: 400;
          font-size: ${theme.sizes.content.p5.fontSize}px;
          line-height: ${theme.sizes.content.p5.lineHeight}px;
          letter-spacing: 0.5px;
          opacity: 0.7;
          margin: 0 4px;
        }
        .copyright_links {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 18px;
        }
        .copyright_links_text {
          font-weight: 700;
          font-size: ${theme.sizes.content.p7.fontSize}px;
          line-height: ${theme.sizes.content.p7.lineHeight}px;
          letter-spacing: 0.1px;
          color: ${theme.green};
          margin-bottom: 10px;
          padding-left: 15px;
          padding-right: 15px;
        }
        .vertical_border:not(:last-child) {
          border-right: 1px solid ${theme.borderColor};
        }
        .google_policy {
          font-size: 10px;
        }
        .google_policy a {
          color: ${theme.green};
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .reserve-heading {
            font-size: ${theme.sizes.content.p2.fontSize}px;
            line-height: ${theme.sizes.content.p2.lineHeight}px;
          }
          .reserve-descp {
            font-size: ${theme.sizes.content.p8.fontSize}px;
            line-height: ${theme.sizes.content.p8.lineHeight}px;
          }
          .custom-input {
            padding: 15px 0px 15px 24px;
          }
          .get-in-touch .custom-button {
            padding: 10px 25px;
          }
        }
      `}</style>
    </div>
  );
}
