import React, { useContext, useState } from "react";
import Link from "next/link";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Icon from "../Icons";
import Initialise from "../Memberships/payment-model/initialise";
import Button, { ButtonKind, ButtonSize } from "../Button";
import WalletConnectContainer from "../Memberships/payment-model/wallet-connect";
import useNotifications from "../../hooks/useNotification";
import ThemeContext from "../../contexts/Main";

const CHAIN_ID = Number(process.env.NEXT_PUBLIC_CHAIN_ID);
const networkNames: any = {
  1: "Ethereum mainnet",
  2: "Morden",
  3: "Ropsten",
  4: "Rinkeby",
  5: "Goerli",
};

type Props = {
  open: boolean;
  closeModel: () => void;
  setWalletAddress: (i: string) => void;
  handleRegistration?: (a: string) => void;
  setWeb3provider?: (i: any) => void;
};

const ConnectWalletModel: React.FC<Props> = ({
  open,
  closeModel,
  setWalletAddress,
  handleRegistration,
  setWeb3provider,
}) => {
  const notifier = useNotifications();
  const [walletLoading, setWalletLoading] = useState(false);
  const [walletConnectModel, setWalletConnectModel] = useState(false);
  const { theme } = useContext(ThemeContext);

  const handleMetaMask = async () => {
    if (window.ethereum) {
      try {
        setWalletLoading(true);
        const [account] = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        if (setWeb3provider) {
          setWeb3provider(window.ethereum);
        }
        setWalletLoading(false);
        setWalletAddress(account);
        closeModel();
        if (handleRegistration) {
          handleRegistration(account);
        }
      } catch (error) {
        setWalletLoading(false);
      }
    } else {
      setWalletLoading(false);
      notifier({
        message: `Please install <a href="https://metamask.io/download/" target="_blank">Metamask</a> Browser extension and login to your <br/><strong>Crypto-wallet</strong> before connecting`,
        style: "error",
      });
    }
  };

  const handleWalletConnect = async (
    provider: WalletConnectProvider | null
  ) => {
    if (provider instanceof WalletConnectProvider && !provider.connected) {
      provider.stop();
      provider = null;
    }
    if (provider) {
      try {
        provider.stop();
        const accounts = provider.accounts;
        if (setWeb3provider) {
          setWeb3provider(provider);
        }
        setWalletLoading(true);
        setWalletLoading(false);
        setWalletAddress(accounts[0]);
        closeModel();
        if (handleRegistration) {
          handleRegistration(accounts[0]);
        }
      } catch (error) {
        setWalletLoading(false);
      }
    }
    setWalletConnectModel(!walletConnectModel);
  };

  return (
    <div className={`popup ${open ? "active" : "inactive"}`}>
      <div className="overlay"></div>
      <div className="popup-content">
        <div className="flex justify-between">
          <div className="flex items-center">
            <h3 className="fnt-bold green-text membership-title uppercase">
              Select a wallet
            </h3>
          </div>
          <Icon
            icon="cross"
            size={30}
            className="cursor-pointer mem-model-close"
            onClick={closeModel}
            color={theme.green}
          />
        </div>
        <p className="py-2.5 sign-wallet-text md:pt-7">
          Sign In with one of available wallet providers or create a new wallet.
        </p>
        <div className="text-center">
          {walletLoading ? (
            <div className="pt-[52px]">
              <Initialise />
            </div>
          ) : (
            <Button
              kind={ButtonKind.primaryCta}
              size={ButtonSize.medium}
              className="min-w-[55%] submit-btn mt-9 md:min-w-full md:mt-8"
              onClick={handleMetaMask}
            >
              <Icon icon="metamask" size={20} className="mr-2" />
              Metamask
            </Button>
          )}
          <Button
            kind={ButtonKind.primaryCta}
            size={ButtonSize.medium}
            className="min-w-[55%] submit-btn mt-6 md:min-w-full"
            onClick={() => setWalletConnectModel(!walletConnectModel)}
          >
            <Icon icon="wallet-connect" size={20} className="mr-2" />
            Wallet Connect
          </Button>
          <div className="pt-[70px] new-wallet-text md:pt-8">
            <p>New to Ethereum⁤?</p>
            <a
              className="wallet-link pb-4"
              href="https://blog.metahollywood.io/choosing-wallet-and-nft-marketplace/"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about wallets
            </a>
          </div>
        </div>
        <WalletConnectContainer
          openModel={walletConnectModel}
          handleWalletConnect={handleWalletConnect}
        />
      </div>

      <style jsx>{`
        .membership-title {
          font-size: ${theme.sizes.content.p2.fontSize}px;
          line-height: ${theme.sizes.content.p2.lineHeight + 6}px;
        }
        .sign-wallet-text {
          font-size: ${theme.sizes.content.p3.fontSize}px;
          line-height: ${theme.sizes.content.p3.lineHeight + 2}px;
        }
        .new-wallet-text {
          font-size: ${theme.sizes.content.p7.fontSize}px;
          line-height: ${theme.sizes.content.p7.lineHeight - 1}px;
        }
        .wallet-link {
          color: ${theme.green};
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .membership-title {
            font-size: ${theme.sizes.content.p5.fontSize}px;
          }
          .sign-wallet-text {
            font-size: ${theme.sizes.content.p3.fontSize - 2}px;
            line-height: ${theme.sizes.content.p3.lineHeight - 6}px;
          }
        }
      `}</style>
      <style jsx>{`
        .popup.active {
          display: block;
          width: 100vw;
          min-height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000;
          min-width: 375px;
        }
        .overlay {
          width: 100%;
          height: 100%;
          background-color: #dfdfdfe6;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        .popup.inactive {
          display: none;
        }
        .popup-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background: #121212;
          box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.5);
          border-radius: 8px;
          background: ${theme.bgPrimary};
          padding: 20px 40px 30px 40px;
          min-width: 800px;
          max-height: 90%;
          overflow: auto;
        }
        .w3m-overlay {
          z-index: 10000;
        }
        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .popup-content {
            min-width: 90%;
          }
          :global(svg.mem-model-close) {
            height: 20px !important;
            width: 20px !important;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .popup-content {
            padding: 20px 20px;
            min-width: 100%;
            min-height: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default ConnectWalletModel;
