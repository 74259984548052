import { useContext } from "react";
import ThemeContext from "../contexts/Theme";


const Marquee: React.FC = ({ ...props }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <section className="marquee">
      <div className="scroll">
        <div className="cs-title">
          {/* <h3 className="category la"></h3> */}
          <h3 className="category new-york"></h3>
          <h3 className="category london"></h3>
          <h3 className="category paris"></h3>
          <h3 className="category tokyo"></h3>
          <h3 className="category hongkong"></h3>
          <h3 className="category miami"></h3>
          <h3 className="category toronto"></h3>
        </div>
        <div className="cs-title">
          {/* <h3 className="category la"></h3> */}
          <h3 className="category new-york"></h3>
          <h3 className="category london"></h3>
          <h3 className="category paris"></h3>
          <h3 className="category tokyo"></h3>
          <h3 className="category hongkong"></h3>
          <h3 className="category miami"></h3>
          <h3 className="category toronto"></h3>
        </div>
      </div>
      <style jsx>{`
        .cs-title {
          min-width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      `}</style>
      <style jsx>{`
        .marquee {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          margin-top: 80px;
        }
        .marquee .scroll {
          display: flex;
          width: 3560px;
        }
        .marquee .scroll div {
          animation: animate-marquee 80s linear infinite;
          animation-delay: -40s;
        }
        .marquee:hover .scroll div,
        .marquee:hover .scroll div:nth-child(2) {
          animation-play-state: paused
        }
        .marquee .scroll div:nth-child(2) {
          animation: animate-marquee-2 80s linear infinite;
          animation-delay: -80s;
        }
        .category {
          display: inline-block;
          margin-left: 32px;
          width: 476px;
          height: 276px;
        }

        .la {
          background-image: url("/images/main_page/locations/lg/la.png")
        }
        .new-york {
          background-image: url("/images/main_page/locations/lg/new-york.png");
        }
        .london {
          background-image: url("/images/main_page/locations/lg/london.png");
        }
        .paris {
          background-image: url("/images/main_page/locations/lg/paris.png");
        }
        .miami {
          background-image: url("/images/main_page/locations/lg/miami.png");
        }
        .tokyo {
          background-image: url("/images/main_page/locations/lg/tokyo.png");
        }
        .hongkong {
          background-image: url("/images/main_page/locations/lg/hongkong.png");
        }
        .toronto {
          background-image: url("/images/main_page/locations/lg/toronto.png");
        }

        @keyframes animate-marquee {
          0% {
            -moz-transform: translateX(100%);
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
          }
          100% {
            -moz-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
          }
        }

        @keyframes animate-marquee-2 {
          0% {
            -moz-transform: translateX(0%);
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
          }
          100% {
            -moz-transform: translateX(-200%);
            -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
          }
        }
      `}</style>
      <style jsx>{`
        .cs-title {
          font-size: ${theme.sizes.boldTitle.h6.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h6.lineHeight}px;
        }
      `}</style>
      <style jsx>{`
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .cs-title {
            font-size: ${theme.sizes.content.p2.fontSize}px;
            line-height: ${theme.sizes.content.p2.lineHeight}px;
          }
          .category {
            width: 250px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .la {
            background-image: url("/images/main_page/locations/sm/la.png")
          }
          .new-york {
            background-image: url("/images/main_page/locations/sm/new-york.png");
          }
          .london {
            background-image: url("/images/main_page/locations/sm/london.png");
          }
          .paris {
            background-image: url("/images/main_page/locations/sm/paris.png");
          }
          .miami {
            background-image: url("/images/main_page/locations/sm/miami.png");
          }
          .tokyo {
            background-image: url("/images/main_page/locations/sm/tokyo.png");
          }
          .hongkong {
            background-image: url("/images/main_page/locations/sm/hongkong.png");
          }
          .toronto {
            background-image: url("/images/main_page/locations/sm/toronto.png");
          }
          .category {
            height: 110px;
            width: 190px;
            margin-left: 20px;
          }
          .marquee .scroll {
            width: 1470px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .marquee {
            padding-top: 20px;
          }
        }
      `}</style>
    </section>
  );
};

export default Marquee;