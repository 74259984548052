import { getEthPrice } from "./PriceCalculate";

export const numberFormat = (amount: number) => {
  return amount?.toLocaleString();
};

export const priceFormat = (price: number, ethPrice: number) => {
  const eth = getEthPrice(Number(price), Number(ethPrice));
  return `${eth} ETH | $${numberFormat(price)}`;
};

export const priceInEth = (price: number, ethPrice: number) => {
  const eth = getEthPrice(Number(price), Number(ethPrice));
  return `${eth} ETH`;
};

export const getContent = (id: number, products: any) => {
  return products?.find((x: any) => x.id === id);
};

export const truncate = (
  text: string,
  startChars: number = 6,
  endChars: number = 4,
  maxLength: number = 13
) => {
  if (text.length > maxLength) {
    var start = text.substring(0, startChars);
    var end = text.substring(text.length - endChars, text.length);
    while (start.length + end.length < maxLength) {
      start = start + ".";
    }
    return start + end;
  }
  return text;
};

export const validateEmail = (inputText: string) => {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
  return inputText.match(mailformat);
};

