export const partnersData = [
  {
    img: "/images/main_page/partner_animoca.png",
    href: "https://www.animocabrands.com/",
  },
  {
    img: "/images/main_page/partner_planet_hollywood.png",
    href: "https://www.planethollywoodintl.com/",
  },
];

export const featuredInData = [
  {
    img: "/images/main_page/featured_in_1.png",
    href: "https://venturebeat.com/games/animoca-brands-and-planet-hollywood-launch-club-3-as-a-physical-private-club-with-web3-perks/",
  },
  {
    img: "/images/main_page/featured_in_2.png",
    href: "https://decrypt.co/123046/planet-hollywood-inks-deal-animoca-brands-launch-nft-club-l-a",
  },
  {
    img: "/images/main_page/featured_in_3.png",
    href: "https://nftplazas.com/animoca-brands-club/",
  },
  {
    img: "/images/main_page/featured_in_4.png",
    href: "",
  },
];
