import React, { useContext } from "react";
import ThemeContext from "../../contexts/Main";
import Icon from "../Icons";

type StepProps = {
  title: string;
  icon: any;
  description: string;
  index: number
};

const PADDING_H = "30px";
const PADDING_V = "20px";

const JoinStep: React.FC<StepProps> = (props) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="step flex flex-col w-[30%] xxl:w-5/12 xl:w-full xl:mb-20">
      <div className="step-content h-full">
        <div className="img-container relative text-center">
          <Icon icon={props.icon} size={165} />
        </div>
        <h3 className="green-text flex"><span className="badge">{props.index + 1}</span>{props.title}</h3>
        <div className="pt-5">{props.description}</div>
      </div>
      <style jsx>{`
        .img-container {
          padding-bottom: 30px;
        }
        .badge {
          background: ${theme.lightGreen};
          height: 40px;
          border-radius: 40px;
          width: 40px;
          text-align: center;
          color: ${theme.bgPrimary};
          margin-right: 15px;
        }
        .step :global(.img-container img) {
          object-fit: contain;
        }
        @media screen and (max-width: ${theme.breakpoints.xxl}px) {
          .step:last-child {
            margin: auto;
            margin-top: 150px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.xl}px) {
          .step:last-child {
            margin-top: 0px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .step :global(.img-container img) {
            object-fit: fill;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .step :global(.img-container img) {
            object-fit: cover;
          }
        }
      `}</style>
      <style jsx>{`
        .step-content {
          padding: ${PADDING_V} ${PADDING_H};
          background-color: ${theme.creamColor};
          box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
          border-radius: 20px;
        }
        h3 {
          font-size: ${theme.sizes.boldTitle.h4.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h4.lineHeight}px;
        }
        .step-content div {
          font-size: ${theme.sizes.content.p3.fontSize}px;
          line-height: ${theme.sizes.content.p3.lineHeight}px;
        }
      `}</style>
    </div>
  );
};

export const STEPS_TO_JOIN = [
  {
    img: "/images/home_page/connect_wallet.png",
    icon: "wallet2", 
    title: "CONNECT WALLET ",
    description: "Explore our premier levels of membership and global access.",
  },
  {
    img: "/images/home_page/pick_membership.png",
    icon: "membership", 
    title: "PICK MEMBERSHIP LEVEL",
    description:
      "Mint your desired membership through Club 3’s secure online portal.",
  },
  {
    img: "/images/home_page/youre_in.png",
    icon: "star-plant", 
    title: "YOU’RE IN",
    description:
      "Find your freshly minted membership NFT transferred directly to your connected HotWallet.",
  },
];

export const STEPS_TO_JOIN_FOR_STRIPE_USER = [
  {
    img: "/images/home_page/connect_wallet.png",
    icon: "wallet2", 
    title: "PURCHASE",
    description: "Explore our premier levels of membership and global access.",
  },
  {
    img: "/images/home_page/pick_membership.png",
    icon: "membership", 
    title: "PICK MEMBERSHIP LEVEL",
    description:
      "Pick your desired membership through Club 3’s secure online portal.",
  },
  {
    img: "/images/home_page/youre_in.png",
    icon: "star-plant", 
    title: "YOU’RE IN",
    description: "",
  },
];

export default JoinStep;
