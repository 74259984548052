import WalletConnect from '@walletconnect/client'
import Provider, {UniversalProvider} from "@walletconnect/universal-provider";
import QRCodeModal from '@walletconnect/qrcode-modal'
import { useEffect, useState } from 'react'
import WalletConnectProvider from '@walletconnect/web3-provider';
import useNotifications from '../../../hooks/useNotification';

const CHAIN_ID = Number(process.env.NEXT_PUBLIC_CHAIN_ID);

type Props = {
  openModel: boolean
  handleWalletConnect: (provider: WalletConnectProvider | null) => Promise<void>;
}

const WalletConnectContainer: React.FC<Props> = function ({
  openModel,
  handleWalletConnect,
}) {
  const notifier = useNotifications();
  const [provider, setProvider] = useState<WalletConnectProvider | null>(null)

  useEffect(() => {

    if(openModel){

      handleConnection().catch((error) => {

        handleWalletConnect(provider);
        notifier({
            message: error as string,
            time: 1500
        });
      });

    }

    //setConnector(connector)

  }, [openModel]);

  const handleConnection = async () => {

    const wcprovider = new WalletConnectProvider({
      qrcode: true,
      qrcodeModal: QRCodeModal,
      rpc: {
        [CHAIN_ID]: "/api/rpc-url"
      }
    });

    setProvider(wcprovider);

    if(wcprovider.connected){
      await wcprovider.close();
    }

    await wcprovider.enable();
    //console.log(accounts);

    // Get provided accounts and chainId
    //const { accounts } = payload.params[0]
    // setWallet(accounts[0])
    handleWalletConnect(wcprovider);
  }

  // const showModal = async () => {
  //   if (!connector) return

  //   if (connector.connected) {
  //     await connector.killSession()
  //   }

  //   await connector.createSession()

  //   const uri = connector.uri
  //   // display QR Code modal
  //   QRCodeModal.open(uri, () => {
  //     console.log('QR Code Modal closed')
  //   })
  // }

  // useEffect(() => {
  //   showModal()
  // }, [openModel])

  return null;
};

export default WalletConnectContainer;
