import theme from "../../../theme/main";
import { IconType } from "../../Icons";

type FooterContactType = Array<{
  icon: IconType;
  name: string;
  value: string;
}>;

export const footerContactDetails: FooterContactType = [
  // {
  //   icon: "phone",
  //   name: "Tel",
  //   value: "310-XXX-XXX",
  // },
  {
    icon: "sms",
    name: "Mail",
    value: "contact@club3members.com",
  },
  {
    icon: "location",
    name: "Address",
    value: "Sunset Blvd, Los Angeles, CA 90069",
  },
  // {
  //   icon: "printer",
  //   name: "Fax",
  //   value: "+1-212-9876543",
  // },
];

export const footerHelpfullLinks = [
  {
    heading: "Join",
    links: [
      {
        name: "Become A Member",
        href: "/join",
        isCta: false,
      },
      {
        name: "Membership Levels",
        href: "/memberships#membership-levels",
        isCta: false,
      },
      {
        name: "Corporate Membership",
        href: "/memberships#corporate-membership",
        isCta: false,
      },
      {
        name: "Club Locations",
        href: "/locations",
        isCta: false,
      },
    ],
  },
  {
    heading: "About",
    links: [
      {
        name: "About Club 3",
        href: "/about",
        isCta: false,
      },
      {
        name: "Our Community",
        href: "/about#for-community",
        isCta: false,
      },
      {
        name: "Join",
        href: "/join",
        isCta: true,
      },
      {
        name: "FAQ",
        href: "/how-it-works#faq",
        isCta: false,
      },
    ],
  },
  {
    heading: "Support",
    links: [
      {
        name: "Concierge",
        href: "mailto:concierge@club3members.com",
        isCta: false,
      },
      {
        name: "Terms & Conditions",
        href: "/docs/Club3_User_Terms_and_Conditions.pdf",
        target: "_blank",
        isCta: false,
      },
      {
        name: "Privacy",
        href: "/docs/Club3_Privacy_Policy.pdf",
        target: "_blank",
        isCta: false,
      },
    ],
  },
];

type SocialLinksType = Array<{
  icon: IconType;
  name: string;
  href: string;
  backgroundColor: string;
}>;

export const footerSocialLinks: SocialLinksType = [
  {
    name: "Instagram",
    href: "/",
    backgroundColor: theme.green,
    icon: "instagram",
  },
  {
    name: "Facebook",
    href: "/",
    backgroundColor: theme.green,
    icon: "facebook",
  },
  {
    name: "Twitter",
    href: "/",
    backgroundColor: theme.green,
    icon: "twitter",
  },
];
