import React, { useContext } from "react";
import ThemeContext from "../../contexts/Main";

type StepProps = {
  title: string;
  icon: any;
  description: string;
  index: number;
};

const PADDING_H = "30px";
const PADDING_V = "64px";

const VerticalStepper: React.FC<StepProps> = (props) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="step completed">
      <div className="v-stepper">
        <div className="circle flex justify-center items-center">{props.index + 1}</div>
        <div className="line"></div>
      </div>

      <div className="step-content">
        <h3 className="fnt-medium">{props.title}</h3>
        <div className="pt-4">{props.description}</div>
      </div>
      <style jsx>{`
        h3 {
          font-size: ${theme.sizes.content.p2.fontSize}px;
          line-height: ${theme.sizes.content.p2.lineHeight}px;
        }
        .step-content div {
          font-size: ${theme.sizes.small.s1.fontSize}px;
          line-height: ${theme.sizes.small.s1.lineHeight + 3}px;
        }
        .step {
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 30px;
        }
        .v-stepper {
          position: relative;
          margin-right: 15px;
        }
        .step .circle {
          background-color: white;
          border-radius: 100%;
          width: 40px;
          height: 40px;
          font-size: ${theme.sizes.boldTitle.h4.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h4.lineHeight}px;
          color: ${theme.bgPrimary};
        }
        .step .line {
          top: 45px;
          left: 20px;
          height: 100%;
          position: absolute;
          border-left: 3px solid gray;
        }
        .step.completed .circle {
          visibility: visible;
          background-color: ${theme.green};
          border-color: ${theme.green};
        }
        .step.completed .line {
          border-left: 1px solid ${theme.green};
        }
        .step.active .circle {
          visibility: visible;
          border-color: ${theme.green};
        }
        .step.empty .circle {
          visibility: hidden;
        }
        .step.empty .line {
          top: 0;
          height: 150%;
        }
        .step:last-child .line {
          border-left: 3px solid white;
          z-index: -1;
        }
        .content {
          margin-left: 20px;
          display: inline-block;
        }
      `}</style>
    </div>
  );
};

export default VerticalStepper;
