import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { wrap } from "popmotion";
import React, { useEffect, useState } from "react";
import Button from "../Button";
import theme from "../../theme/main";
import { ButtonKind, ButtonSize } from "../Button";
import { carouselData } from "./carousel-data";
import Image from "next/image";
import Icon from "../Icons";
import { useRouter } from "next/router";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 100 : -100,
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? 100 : -100,
      opacity: 0,
    };
  },
};

const PADDING_H = 30;
const PADDING_V = 60;

const STRIPE_CLU3_ACCESS_TOKEN = "f495070cabef89be5a8d328457357c0a";
const Carousel = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const router = useRouter();
  const { query } = router;
  const { access } = query;
  const stripeUser = access === STRIPE_CLU3_ACCESS_TOKEN;

  const currentIdx = wrap(0, carouselData.length, page);

  // const [activeIdx, setActiveIdx] = useState(currentIdx);

  // const prevIdx = activeIdx === 0 ? carouselData.length - 1 : activeIdx - 1;

  // const nextIdx = activeIdx === carouselData.length - 1 ? 0 : activeIdx + 1;

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  // useEffect(() => {
  //   setActiveIdx(currentIdx);
  // }, [currentIdx]);

  return (
    <div className="membership-section">
      <AnimatePresence initial={false} custom={direction}>
        <div className="carousel-container h-full">
          {stripeUser ? (
            <h2 className="fnt-bold text-center pt-8 pb-5 light-green-text sm:text-left sm:p-8 sm:pl-[14px]">
              Own YOUR <br className="hidden sm:block" />
              MEMBERSHIP
            </h2>
          ) : (
            <h2 className="fnt-bold text-center pt-8 pb-5 light-green-text sm:text-left sm:p-8 sm:pl-[14px]">
              MINT YOUR <br className="hidden sm:block" />
              MEMBERSHIP
            </h2>
          )}
          {/* <motion.div
            className="item prev-item w-4/6 cursor-pointer flex opacity-20"
            key="prev"
            onClick={() => {
              paginate(-1);
            }}
          >
            <div className="w-2/4 flex flex-col justify-center lg:hidden md:w-full">
              <h3 className="item-title fnt-medium lg:mb-10 sm:mb-5 green-text md:w-2/4">
                {carouselData[prevIdx].title}
              </h3>
              <p className="item-description py-8 lg:mb-10 w-full min-w-full sm:mb-5 md:px-5">
                {carouselData[prevIdx].description}
              </p>

              <Link href={carouselData[prevIdx].ctaUrl}>
                <a className="block min-w-full">
                  <Button
                    kind={ButtonKind.primaryCta}
                    size={ButtonSize.medium}
                    cta={true}
                    className="sm:scale-75"
                  >
                    {carouselData[prevIdx].ctaText}
                  </Button>
                </a>
              </Link>
            </div>
            <div className="w-2/4 flex flex-col items-end lg:w-full lg:min-h-[400px] lg:items-center">
              <div className="item-img relative lg:order-first w-[180px] h-[301px]">
                <Image src={carouselData[prevIdx].img} layout="fill" alt="membership-image" />
              </div>
              <div className="relative w-[96px] h-[15px] right-[40px] lg:right-[0]">
                <Image
                  src="/images/membership/card/shadow.png"
                  alt="shadow"
                  layout="fill"
                />
              </div>
            </div>
          </motion.div> */}
          <motion.div
            className="item active-item w-3/4 xl:w-4/5 m-auto mint-carasoul-h flex lg:flex-col-reverse lg:items-center lg:justify-between lg:w-3/4 sm:w-full lg:min-h-max"
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 200, damping: 50 },
              opacity: { duration: 0.2 },
            }}
          >
            <div className="w-2/4 flex flex-col justify-center lg:items-center lg:text-center lg:w-full">
              <h3 className="item-title fnt-medium lg:mb-10 sm:mb-5 green-text">
                {carouselData[currentIdx].title}
              </h3>
              <p className="item-description py-8 lg:mb-10 w-full min-w-full sm:mb-5 md:px-5 md:pt-6">
                {carouselData[currentIdx].description}
              </p>

              <Link
                href={{
                  pathname: carouselData[currentIdx].ctaUrl,
                  query: router.query,
                }}
              >
                <a className="block min-w-full">
                  <Button
                    kind={ButtonKind.primaryCta}
                    size={ButtonSize.medium}
                    cta={true}
                    className="sm:scale-75"
                  >
                    {carouselData[currentIdx].ctaText}
                  </Button>
                </a>
              </Link>
            </div>
            <div className="w-2/4 flex flex-col items-end lg:w-full lg:min-h-[400px] lg:items-center">
              {/* <div className="item-img relative lg:order-first w-[230px] h-[415px]">
                <Image 
                  src={carouselData[currentIdx].img} 
                  layout="fill" 
                  alt={carouselData[currentIdx].title}
                  placeholder="blur"
                />
              </div>
              <div className="relative w-[135px] h-[25px] right-[50px] lg:right-[0]">
                <Image
                  src="/images/membership/card/shadow.png"
                  alt="shadow"
                  layout="fill"
                />
              </div> */}
              <video muted autoPlay={true} loop>
                <source src={carouselData[currentIdx].video} type="video/mp4" />
              </video>
            </div>
          </motion.div>
          {/* <motion.div
            key="next"
            className="item next-item w-4/6 cursor-pointer opacity-20"
            onClick={() => {
              paginate(1);
            }}
          >
            <div className="w-[2/4] flex flex-col justify-center lg:hidden h-full md:w-full">
              <h3 className="item-title fnt-medium lg:mb-10 sm:mb-5 green-text w-[330px]">
                {carouselData[nextIdx].title}
              </h3>
              <p className="item-description py-8 lg:mb-10 w-full min-w-full sm:mb-5 md:px-5 md:pt-6">
                {carouselData[nextIdx].description}
              </p>

              <Link href={carouselData[nextIdx].ctaUrl}>
                <a className="block min-w-full">
                  <Button
                    kind={ButtonKind.primaryCta}
                    size={ButtonSize.medium}
                    cta={true}
                    className="sm:scale-75"
                  >
                    {carouselData[nextIdx].ctaText}
                  </Button>
                </a>
              </Link>
            </div>
            <div className="w-2/4 lg:w-full flex justify-center">
              <div className="item-img relative lg:order-first h-full w-[320px] lg:min-h-[400px] lg:mb-20 sm:min-h-[200px] sm:mb-5">
                <Image src={carouselData[nextIdx].img} layout="fill" alt="membership-image" />
              </div>
            </div>
          </motion.div> */}
        </div>
        <div
          className="m-auto w-full flex justify-center pb-4 paging"
          key="paginate"
        >
          <button
            className="pagingprev mr-16 pagination-button"
            onClick={() => paginate(-1)}
          >
            <Icon icon="left-arrow" size={25} color={theme.bgPrimary} />
          </button>
          <button
            className="pagingnext pagination-button"
            onClick={() => paginate(1)}
          >
            <Icon icon="right-arrow" size={25} color={theme.bgPrimary} />
          </button>
        </div>
      </AnimatePresence>

      <style jsx>
        {`
          .carousel-container {
            position: relative;
            overflow: hidden;
            padding: 10px 20px;
          }
          :global(.item) {
            padding: 0px ${PADDING_V}px;
          }
          :global(.item > *:first-child) {
            margin-right: 20px;
          }
          :global(.active-item) {
            background-color: ${theme.bgPrimary};
          }
          :global(.prev-item) {
            position: absolute;
            left: -45%;
            top: 58%;
            transform: translateY(-50%);
            padding: ${PADDING_V}px ${PADDING_H}px;
          }
          :global(.next-item) {
            position: absolute;
            top: 58%;
            transform: translateY(-50%);
            right: -45%;
            padding: ${PADDING_V}px ${PADDING_H}px;
          }
          :global(.item-img) {
            overflow: hidden;
          }
          .item-img-bg {
            background: url("/images/main_page/Overlay.png");
            background-size: 100% 100%;
          }
          .membership-section h2 {
            font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
            line-height: ${theme.sizes.boldTitle.h3.lineHeight}px;
          }
          :global(.mint-carasoul-h) {
            min-height: 510px;
          }
          @media screen and (max-width: 1512px) {
            :global(.mint-carasoul-h) {
              min-height: 385px;
            }
          }
          @media screen and (max-width: ${theme.breakpoints.xl}px) {
            :global(.prev-item) {
              left: -60%;
            }
            :global(.next-item) {
              right: -60%;
            }
          }
          @media screen and (max-width: ${theme.breakpoints.lg}px) {
            :global(.item > *:first-child) {
              margin-right: 0px;
            }
          }
          @media screen and (max-width: ${theme.breakpoints.sm}px) {
            :global(.prev-item) {
              left: -100%;
            }
            :global(.next-item) {
              right: -100%;
            }
          }
        `}
      </style>
      <style jsx>
        {`
          .pagingprev,
          .pagingnext {
            background-color: ${theme.bgPrimary};
          }
          .carousel-container,
          .paging {
            background-color: ${theme.creamColor};
          }
          :global(.item-title) {
            font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
            line-height: ${theme.sizes.boldTitle.h3.lineHeight}px;
          }
          :global(.item-description) {
            font-size: ${theme.sizes.content.p4.fontSize}px;
            line-height: ${theme.sizes.content.p4.lineHeight}px;
          }
          .pagination-button {
            background: ${theme.lightGreen};
            color: ${theme.bgPrimary};
            height: ${theme.buttons.base.borderRadius};
            width: ${theme.buttons.base.borderRadius};
            border-radius: ${theme.buttons.base.borderRadius};
          }
        `}
      </style>
    </div>
  );
};

export default Carousel;
