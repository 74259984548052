import "../styles/global.css";
import { SessionProvider } from "next-auth/react";
import { Session } from "next-auth";
import {
  Context,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from "react";
import Head from "next/head";
import fetcher from "fetcher";
import Script from "next/script";
import { SWRConfig } from "swr";
import { CNOptions, NotificationContext } from "../contexts/Notification";
import Notifier from "../components/Notifier";
import AuthGuard from "../components/AuthGuard";
import { useRouter } from "next/router";

type AppProps = {
  Component: FC;
  pageProps: {
    session: Session | null;
  };
};

const swrConfigOpts = {
  fetcher,
};

type Obj = {
  animoca: boolean;
  wallet: string;
  web3provider: any;
};

type AnimocanDispatch = Dispatch<SetStateAction<Obj>>;

const animocaDispatch: AnimocanDispatch = () => null;

export const PriceContext: Context<[Obj, Dispatch<SetStateAction<Obj>>]> =
  createContext<[Obj, AnimocanDispatch]>([
    {
      animoca: false,
      wallet: "",
      web3provider: null,
    },
    animocaDispatch,
  ]);

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const notificationState = useState<CNOptions>(null);
  const router = useRouter();
  const { isReady, query } = router;
  const [isAnimoca, setAnimoca] = useState({
    animoca: false,
    wallet: "",
    web3provider: null,
  });

  if (!isReady) {
    return null;
  }

  return (
    <SessionProvider session={session}>
      <Head>
        <link
          rel="preload"
          href="/fonts/Montserrat-Regular.woff"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Montserrat-Medium.woff"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Montserrat-Bold.woff"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Montserrat-Black.woff"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Noto_Sans/NotoSans-Regular.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Noto_Sans/NotoSans-Bold.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Noto_Sans/NotoSans-SemiBold.ttf"
          as="font"
          crossOrigin=""
        />
        <link rel="shortcut icon" href="/images/favicon.svg" />
      </Head>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        strategy="afterInteractive"
      ></Script>
      <Script
        async
        type="text/javascript"
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UhnVMm"
        strategy="afterInteractive"
      ></Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>
      <style jsx global>{`
        @font-face {
          font-family: "Montserrat-Regular";
          src: url("/fonts/Montserrat-Regular.woff");
          font-display: swap;
        }
        @font-face {
          font-family: "Montserrat-Bold";
          src: url("/fonts/Montserrat-Bold.woff");
          font-display: swap;
        }
        @font-face {
          font-family: "Montserrat-Medium";
          src: url("/fonts/Montserrat-Medium.woff");
          font-display: swap;
        }
        @font-face {
          font-family: "Montserrat-Black";
          src: url("/fonts/Montserrat-Black.woff");
          font-display: swap;
        }
        @font-face {
          font-family: "NotoSans-Regular";
          src: url("/fonts/Noto_Sans/NotoSans-Regular.ttf");
          font-display: swap;
        }
        @font-face {
          font-family: "NotoSans-Medium";
          src: url("/fonts/Noto_Sans/NotoSans-Medium.ttf");
          font-display: swap;
        }
        @font-face {
          font-family: "NotoSans-Bold";
          src: url("/fonts/Noto_Sans/NotoSans-Bold.ttf");
          font-display: swap;
        }
        @font-face {
          font-family: "NotoSans-SemiBold";
          src: url("/fonts/Noto_Sans/NotoSans-SemiBold.ttf");
          font-display: swap;
        }
      `}</style>
      <SWRConfig value={swrConfigOpts}>
        <NotificationContext.Provider value={notificationState}>
          <AuthGuard isReady={isReady} query={query}>
            <PriceContext.Provider value={[isAnimoca, setAnimoca]}>
              <Component {...pageProps} />
            </PriceContext.Provider>
          </AuthGuard>
          <Notifier />
        </NotificationContext.Provider>
      </SWRConfig>
    </SessionProvider>
  );
}
