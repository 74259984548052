import React, { useContext } from "react";
import ThemeContext from "../../contexts/Main";
import Button, { ButtonKind, ButtonSize } from "../../components/Button";
import Image from "next/image";
import JoinStep, {
  STEPS_TO_JOIN,
  STEPS_TO_JOIN_FOR_STRIPE_USER,
} from "./JoinSteps";
import VerticalStepper from "./VerticalStepper";
import Carousel from "./Carousel";
import Link from "next/link";
import ComingSoonBanner from "../ComingSoonBanner";
import SunsetImg from "../../public/images/main_page/sunset.png";
import LAMainImg from "../../public//images/main_page/la-fingertips.png";
import ClubCulture from "../../public/images/main_page/club3-culture.png";
import Icon from "../Icons";
import MintCarousel from "./MintCarousel";
import useWindowSize from "../../hooks/UseWindowSize";
import { useRouter } from "next/router";
import Tooltip from "../Tooltip";
import { featuredInData, partnersData } from "./partners-data";

const MARGIN_H = "80px";
const PADDING_H = "40px";
const PADDING_V = "64px";
const STRIPE_CLU3_ACCESS_TOKEN = "f495070cabef89be5a8d328457357c0a";
const LIVE = process.env.NEXT_PUBLIC_LIVE;

export default function Landing() {
  const { theme } = useContext(ThemeContext);
  const [width] = useWindowSize();
  const router = useRouter();
  const { query } = router;
  const { access } = query;
  const stripeUser = access === STRIPE_CLU3_ACCESS_TOKEN;

  return (
    <>
      <section className="hero flex flex-col">
        <div className="flex xl:flex-col xl:items-center">
          <h1 className="fnt-bold sm:flex-none hero-title neon-text pt-[50px] md:pt-0">
            WELCOME TO
          </h1>
          <Icon
            icon="club3-logo"
            className="club3-logo xl:pt-8 md:pt-0"
            size={498}
          />
        </div>
        <div className="caption-row flex justify-end items-center pt-8 xl:flex-col md:pt-2.5 mr-[105px] xl:mr-0">
          <div className="caption white-text fnt-medium mr-[100px] xl:text-center xl:mr-0">
            A members only social club curated for the Web3 Community.
          </div>
          <div className="xl:pt-5">
            {LIVE == "prelaunch" ? (
              <Tooltip content="Minting date to be announced" direction="top">
                <Button
                  kind={ButtonKind.primaryCta}
                  size={ButtonSize.medium}
                  className="mt-4"
                  aria-disabled
                  style={{
                    backgroundColor: "grey",
                    borderColor: "grey",
                    cursor: "not-allowed",
                  }}
                >
                  Join Now
                </Button>
              </Tooltip>
            ) : (
              <Link
                href={{
                  pathname: "/join",
                  query: router.query,
                }}
              >
                <a>
                  <Button
                    kind={ButtonKind.neon}
                    size={ButtonSize.medium}
                    cta={true}
                  >
                    Join Now
                  </Button>
                </a>
              </Link>
            )}
          </div>
        </div>
      </section>

      <section className="first-of-kind-section y-spacing x-spacing flex flex-col justify-center x-spacing-0 md:justify-end md:items-end">
        <div className="fnt-medium white-text md:text-right w-7/12 md:w-[auto]">
          A first of its kind private club featuring best-in-class design,
          hospitality, IRL community and bespoke events.
        </div>
      </section>

      <section className="partner-section y-spacing x-spacing flex flex-col justify-center">
        <div className="partner-section-title light-green-text uppercase text-center fnt-bold">
          Partners
        </div>
        <div className="flex justify-center pb-10 partner-image-container">
          {partnersData.map((ele: any, index) => {
            return (
              <a href={ele.href} target="_blank" rel="noreferrer">
                <img src={ele.img} key={index} className="partner-image" />
              </a>
            );
          })}
        </div>
      </section>

      <section className="heart-section y-spacing x-spacing flex xl:flex-col xl:justify-center xl:text-center x-spacing-0 in-heart-section">
        <div className="img-container relative w-full min-w-[600px] box-border xl:mt-8 xl:min-w-full md:h-[219px]">
          <Image
            src={SunsetImg}
            layout="fill"
            objectFit="cover"
            alt="section-bg"
            placeholder="blur"
            className="round-image"
          />
        </div>
        <div className="heart-section-row heat-seaction-spacing xl:order-first">
          <div className="heart-title light-green-text fnt-medium">
            IN THE HEART OF SUNSET, IN THE CENTER OF IT ALL.
          </div>
          <div className="heart-text xl:pt-8 md:pt-4">
            Our premier club location offers 10,000 square ft. of hospitality in
            West Hollywood. With unbeatable access and convenience to the most
            desirable spots in the city.
          </div>
          <Link
            href={{
              pathname: "/join",
              query: router.query,
            }}
          >
            <a>
              <Button
                kind={ButtonKind.primaryCta}
                size={ButtonSize.medium}
                cta={true}
                className="lg:w-full"
              >
                Become a Member
              </Button>
            </a>
          </Link>
        </div>
      </section>

      <section className="membership ">
        {width < 768 ? <MintCarousel /> : <Carousel />}
      </section>

      <section className="featured-section y-spacing x-spacing flex flex-col justify-center">
        <div className="featured-section-title light-green-text uppercase text-center fnt-bold">
          FEATURED IN
        </div>
        <div className="flex justify-center featured-image-container">
          {featuredInData.map((ele: any, index) => {
            return ele.href ? (
              <a href={ele.href} target="_blank" rel="noreferrer">
                <img src={ele.img} key={index} className="featured-image" />
              </a>
            ) : (
              <img src={ele.img} key={index} className="featured-image" />
            );
          })}
        </div>
      </section>

      <div className="join-section y-spacing x-spacing md:pt-[60px]">
        <h3 className="fnt-semiMedium light-green-text">JOIN CLUB 3 TODAY</h3>
        <div className="sub green-text">
          No application fees,
          <br className="hidden md:block" /> No recurring fees.{" "}
        </div>
        <div className="step-row flex flex-wrap justify-between mt-12 md:hidden">
          {stripeUser
            ? STEPS_TO_JOIN_FOR_STRIPE_USER.map((steps, i) => (
                <JoinStep key={i} {...steps} index={i} />
              ))
            : STEPS_TO_JOIN.map((steps, i) => (
                <JoinStep key={i} {...steps} index={i} />
              ))}
        </div>
        <div className="stepper-container hidden md:block">
          {stripeUser
            ? STEPS_TO_JOIN_FOR_STRIPE_USER.map((steps, i) => (
                <VerticalStepper key={i} {...steps} index={i} />
              ))
            : STEPS_TO_JOIN.map((steps, i) => (
                <VerticalStepper key={i} {...steps} index={i} />
              ))}
        </div>
        <div className="text-center pt-6 hidden md:block">
          {LIVE == "prelaunch" ? (
            <Tooltip content="Minting date to be announced" direction="top">
              <Button
                kind={ButtonKind.primaryCta}
                size={ButtonSize.medium}
                className="mt-4"
                aria-disabled
                style={{
                  backgroundColor: "grey",
                  borderColor: "grey",
                  cursor: "not-allowed",
                }}
              >
                Join Now
              </Button>
            </Tooltip>
          ) : (
            <Link
              href={{
                pathname: "/join",
                query: router.query,
              }}
            >
              <a>
                <Button
                  kind={ButtonKind.primaryCta}
                  size={ButtonSize.medium}
                  cta={true}
                  className="lg:w-full"
                >
                  Join Now
                </Button>
              </a>
            </Link>
          )}
        </div>
      </div>

      <section className="heart-section y-spacing x-spacing flex xl:flex-col xl:justify-center in-heart-section">
        <div className="img-container relative w-full min-w-[600px] box-border xl:mt-8 md:min-w-full md:h-[219px]">
          <Image
            src={LAMainImg}
            layout="fill"
            objectFit="cover"
            alt="section-bg"
            placeholder="blur"
            className="round-image"
          />
        </div>
        <div className="heart-section-row heat-seaction-spacing la-fingertips-spacing xl:order-first">
          <div className="heart-title light-green-text fnt-medium">
            LA AT YOUR FINGERTIPS
          </div>
          <div className="heart-text xl:pt-8 md:pt-4 la-fingertips-desc">
            Our first Club 3 location has landed in the most desired spot in Los
            Angeles. 10,000 sq. feet perfectly positioned on the Sunset Strip in
            West Hollywood - a stone’s throw to the hills, quick access to DTLA,
            and a straight shot to LAX. The best of the west, the only place
            Club 3 would settle.
          </div>
        </div>
      </section>

      <section className="coming-soon">
        <ComingSoonBanner />
      </section>

      <section className="heart-section club-culture y-spacing x-spacing flex xl:flex-col xl:justify-center xl:text-center">
        <div className="heart-section-row w-2/4 xl:w-full xl:mt-8">
          <div className="heart-title fnt-medium light-green-text">
            CLUB 3 IS A CULTURE
          </div>
          <div className="heart-text xl:pt-4 xl:pb-2">
            A community built for Web3, by Web3. <br />
            Designed for the artists, the creators, the rule-breakers, the next
            gen digital pioneers. A real place to connect, build, play,
            collaborate, and unwind for the burgeoning digital community.
          </div>
          {LIVE == "prelaunch" ? (
            <Tooltip content="Minting date to be announced" direction="top">
              <Button
                kind={ButtonKind.primaryCta}
                size={ButtonSize.medium}
                className="mt-4"
                aria-disabled
                style={{
                  backgroundColor: "grey",
                  borderColor: "grey",
                  cursor: "not-allowed",
                }}
              >
                Join Now
              </Button>
            </Tooltip>
          ) : (
            <Link
              href={{
                pathname: "/join",
                query: router.query,
              }}
            >
              <a>
                <Button
                  kind={ButtonKind.primaryCta}
                  size={ButtonSize.medium}
                  cta={true}
                  className="lg:w-full"
                >
                  Join Now
                </Button>
              </a>
            </Link>
          )}
        </div>
        <div className="relative w-2/4 box-border xl:w-full xl:order-first">
          <div className="relative min-h-[100%] xl:min-h-[400px] md:min-h-[220px]">
            <Image
              src={ClubCulture}
              layout="fill"
              objectFit="cover"
              alt="club3-culture"
              placeholder="blur"
              className="round-image"
            />
          </div>
        </div>
      </section>
      <style jsx>{`
        .cities {
          position: relative;
          justify-content: space-between;
        }
        .ct-names {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 2;
          width: 100%;
          text-align: center;
        }
        .ct-img {
          background-color: ${theme.heroBg};
          width: 100%;
          height: 585px;
          position: relative;
        }
        .hero {
          border-radius: 5px;
          min-height: 620px;
          background: linear-gradient(
              120.32deg,
              rgba(0, 0, 0, 0.5) 12.07%,
              rgba(0, 0, 0, 0.5) 30.87%,
              rgba(23, 56, 0, 0.5) 65.16%,
              rgba(23, 56, 0, 0) 90.34%
            ),
            url("/images/main_page/main.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        .hero h1 {
          letter-spacing: -1.5px;
          margin: 0;
        }
        .caption {
          max-width: 559px;
        }
        .first-of-kind-section {
          height: 570px;
          border-radius: 20px;
          background: linear-gradient(
              120.32deg,
              rgba(0, 0, 0, 0.5) 12.07%,
              rgba(0, 0, 0, 0.5) 30.87%,
              rgba(23, 56, 0, 0.5) 65.16%,
              rgba(23, 56, 0, 0) 90.34%
            ),
            url("/images/main_page/first-kind-section.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        .heart-section {
          min-height: 500px;
        }
        .heart-section .img-container {
          min-height: 400px;
        }
        .tag {
          text-transform: uppercase;
        }
        .visual-section {
          min-height: 480px;
        }
        :global(svg.club3-logo) {
          height: 401px !important;
        }
        .heart-text.la-fingertips-mb {
          margin-bottom: 0px;
        }
        .first-location.heart-section {
          min-height: auto;
        }
        :global(svg.club3-logo path) {
          fill: ${theme.bgPrimary};
        }
        .partner-section-title {
          padding-top: 44px;
          padding-bottom: 40px;
          font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h3.lineHeight + 5}px;
        }
        .partner-image-container {
          gap: 126px;
          align-items: center;
        }
        .partner-image {
          object-fit: contain;
        }
        .featured-section-title {
          padding-top: 24px;
          padding-bottom: 40px;
          font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h3.lineHeight + 5}px;
        }
        .featured-image-container {
          gap: 77px;
          flex-wrap: wrap;
          align-items: center;
        }
        .featured-image {
          object-fit: contain;
        }
        @media screen and (min-width: 1590px) {
          .la-fingertips-desc {
            width: 800px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .hero {
            min-height: 360px;
            border-radius: 0;
          }
          .first-of-kind-section {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 0;
          }
        }
      `}</style>
      <style jsx>{`
        .hero {
          background-color: ${theme.bgSecondary};
          padding: 55px ${theme.spacing.xxl.x}px;
          color: ${theme.fontColorPrimary};
          padding-right: 74px;
        }
        .cities {
          padding-left: ${theme.spacing.xxl.x}px;
          padding-right: ${theme.spacing.xxl.x}px;
        }
        .hero h1 {
          font-size: ${theme.sizes.boldTitle.h5.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h5.lineHeight - 3}px;
        }

        .caption {
          font-size: ${theme.sizes.mediumTitle.h6.fontSize}px;
          line-height: ${theme.sizes.mediumTitle.h6.lineHeight - 1}px;
        }

        .first-of-kind-section {
          color: ${theme.fontColorPrimary};
        }

        .first-of-kind-section div {
          font-size: ${theme.sizes.mediumTitle.h6.fontSize}px;
          line-height: ${theme.sizes.mediumTitle.h6.lineHeight - 1}px;
          margin: ${PADDING_V} ${PADDING_H};
        }

        .heart-section > *:first-child {
          margin-right: ${MARGIN_H};
        }

        .tag {
          padding: ${theme.sizes.button.small.padding};
          text-transform: uppercase;
          background-color: ${theme.bgSecondary};
          font-size: ${theme.sizes.button.small.fontSize}px;
          line-height: ${theme.sizes.button.small.lineHeight}px;
        }

        .heart-title {
          font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h3.lineHeight}px;
          margin: ${PADDING_V} 0;
        }
        .heart-text {
          font-size: ${theme.sizes.content.p2.fontSize + 1}px;
          line-height: ${theme.sizes.content.p2.lineHeight}px;
          margin-bottom: ${PADDING_V};
        }
        .join-section h3 {
          font-size: ${theme.sizes.boldTitle.h3.fontSize}px;
          line-height: ${theme.sizes.boldTitle.h3.lineHeight}px;
        }

        .join-section .sub {
          font-size: ${theme.sizes.regularTitle.h2.fontSize}px;
          line-height: ${theme.sizes.regularTitle.h2.lineHeight}px;
        }
        .la-fingertips-text {
          font-size: ${theme.sizes.regularTitle.h3.fontSize}px;
          line-height: ${theme.sizes.regularTitle.h3.lineHeight}px;
        }

        @media screen and (max-width: ${theme.breakpoints.xl}px) {
          .hero {
            background-color: ${theme.bgSecondary};
            padding: ${theme.spacing.md.y / 2}px ${theme.spacing.xl.x}px;
          }
          .cities {
            padding-left: ${theme.spacing.xl.x}px;
            padding-right: ${theme.spacing.xl.x}px;
          }
          .first-of-kind-section div {
            font-size: ${theme.sizes.mediumTitle.h3.fontSize * 0.85}px;
            line-height: ${theme.sizes.mediumTitle.h3.lineHeight * 0.85}px;
          }
          .heart-title {
            margin: 0;
          }
          .heart-text {
            margin-bottom: 20px;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .cities {
            padding-left: ${theme.spacing.lg.x}px;
            padding-right: ${theme.spacing.lg.x}px;
          }
          .first-of-kind-section div {
            font-size: ${theme.sizes.mediumTitle.h3.fontSize * 0.6}px;
            line-height: ${theme.sizes.mediumTitle.h3.lineHeight * 0.6}px;
          }
          .heart-section > *:first-child {
            margin-right: 0;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .hero {
            background-color: ${theme.bgSecondary};
            padding: ${theme.spacing.md.x - 7}px ${theme.spacing.md.x}px;
          }
          .cities {
            padding-left: ${theme.spacing.md.x}px;
            padding-right: ${theme.spacing.md.x}px;
          }
          .hero h1 {
            font-size: ${theme.sizes.boldTitle.h1.fontSize * 0.15}px;
            line-height: ${theme.sizes.boldTitle.h1.lineHeight * 0.27}px;
          }
          .first-of-kind-section {
            height: 280px;
          }
          .first-of-kind-section div {
            font-size: ${theme.sizes.content.p4.fontSize - 2}px;
            line-height: ${theme.sizes.content.p4.lineHeight}px;
            margin: 25px;
            font-family: ${theme.fonts.bold.name};
            font-weight: ${theme.fonts.bold.weight};
          }
          .caption {
            font-size: ${theme.sizes.content.p7.fontSize}px;
            line-height: ${theme.sizes.content.p7.lineHeight}px;
            font-family: ${theme.fonts.semiMedium.name};
            font-weight: ${theme.fonts.semiMedium.weight};
            max-width: 250px;
          }
          .heart-title {
            font-size: ${theme.sizes.content.p1.fontSize}px;
            line-height: ${theme.sizes.content.p1.lineHeight + 2}px;
            margin: 0;
          }
          .partner-section-title,
          .featured-section-title {
            font-size: ${theme.sizes.content.p1.fontSize}px;
            line-height: ${theme.sizes.content.p1.lineHeight + 2}px;
            padding-top: 0px;
          }
          .partner-image-container,
          .featured-image-container {
            gap: 40px;
          }
          .partner-image,
          .featured-image {
            width: 150px;
          }
          .featured-section {
            padding-top: 48px;
          }
          .heart-text {
            font-size: ${theme.sizes.content.p7.fontSize}px;
            line-height: ${theme.sizes.content.p7.lineHeight}px;
            margin-bottom: 16px;
          }
          .heat-seaction-spacing {
            padding-left: ${theme.spacing.md.x}px;
            padding-right: ${theme.spacing.md.x}px;
          }
          .la-fingertips-spacing.heat-seaction-spacing {
            padding-left: 0px;
            padding-right: 0px;
          }
          .join-section h3 {
            font-size: ${theme.sizes.mediumTitle.h5.fontSize}px;
            line-height: ${theme.sizes.mediumTitle.h5.lineHeight + 2}px;
            font-family: ${theme.fonts.bold.name};
            font-weight: ${theme.fonts.bold.weight};
          }
          .join-section .sub {
            font-size: ${theme.sizes.content.p3.fontSize}px;
            line-height: ${theme.sizes.content.p3.lineHeight - 3}px;
            color: ${theme.fontColorPrimary};
          }
          .la-fingertips-text {
            font-size: ${theme.sizes.content.p6.fontSize + 1}px;
            font-family: ${theme.fonts.medium.name};
            font-weight: ${theme.fonts.medium.weight};
          }
          .heart-title.green-text {
            color: ${theme.lightGreen};
            font-family: ${theme.fonts.semiMedium.name};
            font-weight: ${theme.fonts.semiMedium.weight};
          }
          .la-fingertips-title.heart-title.green-text {
            color: ${theme.green};
            font-family: ${theme.fonts.bold.name};
            font-weight: ${theme.fonts.bold.weight};
          }
          .heart-section .img-container,
          .heart-section {
            min-height: auto;
          }
        }

        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .cities {
            padding-left: ${theme.spacing.sm.x}px;
            padding-right: ${theme.spacing.sm.x}px;
          }
          .membership-section br {
            display: block;
          }
          .in-heart-section.heart-section,
          .heart-section.club-culture {
            min-height: auto;
          }
        }
      `}</style>
      <style jsx global>{`
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          svg.club3-logo {
            height: 131px !important;
            width: 162px !important;
          }
        }
      `}</style>
    </>
  );
}
