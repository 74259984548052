import * as React from "react";
import { useState, useContext } from "react";
import { motion } from "framer-motion";
import { wrap } from "popmotion";
import ThemeContext from "../../contexts/Main";
import useWindowSize from "../../hooks/UseWindowSize";
import Image from "next/image";
import RestaurantImg from "../../public/images/location/restaurant.png";
import BarImg from "../../public/images/location/bar.png";
import PrivateImg from "../../public/images/location/private.png";
import LuxuryImg from "../../public/images/location/luxury.png";
import DiningImg from "../../public/images/location/dining.png";
import FounderLoungeImg from "../../public/images/location/founder-lounge.png";
import MeetingImg from "../../public/images/location/meeting-room.png";
import ArtworkImg from "../../public/images/location/artworks.png";
import MultipleBars from "../../public/images/how_it_works/freedom.png";
import Icon from "../Icons";

const contentMobile = [
  {
    text: "Multiple Restaurants and Dining Areas",
    img: RestaurantImg,
  },
  {
    text: "Rooftop Bar with Scenic Views",
    img: BarImg,
  },
  {
    text: "Private Karaoke Lounges",
    img: PrivateImg,
  },
  {
    text: "Media Rooms",
    img: LuxuryImg,
  },
  {
    text: "Private Dining",
    img: DiningImg,
  },
  {
    text: "Founder’s only Cocktail lounge ",
    img: FounderLoungeImg,
  },
  {
    text: "Private Meeting Rooms",
    img: MeetingImg,
  },
  {
    text: "Curated Gallery of Digital Artworks",
    img: ArtworkImg,
  },
  {
    text: "Multiple Bars",
    img: MultipleBars
  }
];

let content = [...contentMobile];

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
const sliderSize = 3;

const variants = {
  enter: () => {
    return {
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: () => {
    return {
      zIndex: 0,
      opacity: 0,
    };
  },
};

function getContent(currentIndex: number) {
  const total = currentIndex * sliderSize;
  let sliced = [];
  // if (total === 0) {
  //   sliced = content.slice(total, sliderSize);
  // } else
  if (currentIndex < 0) {
    if (currentIndex === -1) {
      sliced = content.slice(total);
    } else {
      sliced = content.slice(total, total + sliderSize);
    }
  } else {
    sliced = content.slice(total - sliderSize, total);
  }
  // if array length is less than 3
  if (sliced.length < 3) {
    if (currentIndex < 0) {
      let data = [...content];
      data = data.reverse();
      content = content.concat(content);
      sliced = content.slice(total, total + sliderSize);
    } else {
      content = content.concat(content);
      sliced = content.slice(total - sliderSize, total);
    }
  }
  return sliced;
}

const SliderContent = () => {
  const { theme } = useContext(ThemeContext);
  const [windowWidth] = useWindowSize();
  const [[page, direction], setPage] = useState([0, 0]);
  // const [slider, setSlider] = useState<any>([])
  const [sliderIndex, setSliderIndex] = useState(1);

  const index = wrap(0, windowWidth < 767 ? contentMobile.length : 3, page);

  const paginate = (newDirection: number, step: string) => {
    setPage([page + newDirection, newDirection]);
    let newIndex = sliderIndex + newDirection;
    if (newIndex === 0) {
      if (step === "next") {
        newIndex = 1;
      } else {
        newIndex = -1;
      }
    }
    setSliderIndex(newIndex);
  };

  const slider = getContent(sliderIndex);

  return (
    <div className="relative">
      <motion.div
        key={page}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          opacity: { duration: 0.2 },
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);
          if (swipe < -swipeConfidenceThreshold) {
            paginate(1, "next");
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1, "prev");
          }
        }}
      >
        <>
          {windowWidth < 767 ? (
            <div className="wi-des-div flex justify-between w-full lg:flex-col lg:items-center">
              <div className="wi-card flex flex-col flex-1 mr-6 lg:mr-0">
                <div className="wi-img relative h-60 mb-6 w-[426px] sm:w-[326px]">
                  <Image
                    src={content[index].img}
                    layout="fill"
                    alt="slider-image"
                    placeholder="blur"
                  />
                </div>
                <div className="wi-des w-full text-center" key={index}>
                  {content[index].text}
                </div>
              </div>
            </div>
          ) : (
            <div className="wi-des-div grid grid-cols-3 gap-4 lg:ml-[15px]">
              {slider.map((item: any, index: number) => (
                <div className="wi-card flex flex-col flex-1 mr-6" key={index}>
                  <div className="wi-img relative h-60 mb-6">
                    <Image
                      src={item.img}
                      layout="fill"
                      alt="slider-image"
                      placeholder="blur"
                      className="round-image"
                    />
                  </div>
                  <div className="wi-des w-full text-center" key={index}>
                    {item.text}
                  </div>
                </div>
              ))}
            </div>
          )}
          <Dots
            content={windowWidth < 767 ? contentMobile : slider}
            active={index}
            color={theme.lightGreen}
            index={index}
            paginate={paginate}
          />
        </>
      </motion.div>
      <div className="next" onClick={() => paginate(1, "next")}>
        <Icon icon="right-arrow" size={25} color={theme.bgPrimary}/>
      </div>
      <div className="prev" onClick={() => paginate(-1, "prev")}>
        <Icon icon="left-arrow" size={25} color={theme.bgPrimary}/>
      </div>
      <style jsx>{`
        .next,
        .prev {
          top: 30%;
          position: absolute;
          background: ${theme.lightGreen};
          color: ${theme.bgPrimary};
          border-radius: 30px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          z-index: 2;
        }
        .next {
          right: -20px;
        }
        .prev {
          left: -45px;
        }
        .wi-des-div {
          margin-bottom: 40px;
        }
        .wi-des {
          letter-spacing: -0.2px;
        }
        .wi-des {
          font-size: ${theme.sizes.content.p2.fontSize + 1}px;
          line-height: ${theme.sizes.content.p2.lineHeight}px;
        }
        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .prev {
            left: -30px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .prev {
            left: 0px;
          }
          .next {
            right: 0px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .wi-des {
            font-size: ${theme.sizes.content.p5.fontSize}px;
            line-height: ${theme.sizes.content.p6.lineHeight - 1}px;
          }
          .prev {
            left: -25px;
          }
          .next {
            right: -25px;
          }
        }
      `}</style>
    </div>
  );
};

export default SliderContent;

export const Dots = ({
  content,
  active,
  color,
  className,
  paginate,
  index,
}: any) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`dot-container ${className}`}>
      {content.map((_: any, i: number) => (
        <motion.div
          key={i}
          className={`dot ${active === i ? "active" : ""}`}
          initial={false}
          animate={{
            scale: active === i ? 1.5 : 1,
            opacity: active === i ? 1 : 0.5,
          }}
          onClick={() => {
            paginate(i - index);
          }}
        />
      ))}
      <style jsx>{`
        .dot-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          position: absolute;
          bottom: -40px;
          left: 50%;
          transform: translateX(-50%);
        }

        :global(.dot.active) {
          background-color: ${color};
        }

        :global(.dot) {
          width: 10px;
          height: 10px;
          background-color: ${theme.slightGray};
          border-radius: 5px;
          opacity: 0.8;
        }

        :global(.dot:not(:last-child)) {
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
};
