import React, { useState } from "react";

const Tooltip = (props: any) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"}`}>
          {/* Content */}
          {props.content}
        </div>
      )}
      <style jsx>{`
        .Tooltip-Wrapper {
          display: inline-block;
          position: relative;
        }

        .Tooltip-Tip {
          position: absolute;
          border-radius: 4px;
          left: 50%;
          transform: translateX(-50%);
          padding: 6px;
          color: white;
          background: black;
          font-size: 14px;
          font-family: sans-serif;
          line-height: 1;
          z-index: 100;
          white-space: nowrap;
        }

        .Tooltip-Tip::before {
          content: " ";
          left: 50%;
          border: solid transparent;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-width: 6px;
          margin-left: calc(6px * -1);
        }

        .Tooltip-Tip.top {
          top: calc(30px * -1);
        }
        .Tooltip-Tip.top::before {
          top: 100%;
          border-top-color: black;
        }

        .Tooltip-Tip.right {
          left: calc(100% + 30px);
          top: 50%;
          transform: translateX(0) translateY(-50%);
        }
        .Tooltip-Tip.right::before {
          left: calc(6px * -1);
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-right-color: black;
        }

        .Tooltip-Tip.bottom {
          bottom: calc(30px * -1);
        }
        .Tooltip-Tip.bottom::before {
          bottom: 100%;
          border-bottom-color: black;
        }

        .Tooltip-Tip.left {
          left: auto;
          right: calc(100% + 30px);
          top: 50%;
          transform: translateX(0) translateY(-50%);
        }
        .Tooltip-Tip.left::before {
          left: auto;
          right: calc(6px * -2);
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-left-color: black;
        }
      `}</style>
    </div>
  );
};

export default Tooltip;
