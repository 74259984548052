import Link from "next/link";
import { useContext, useEffect, useRef, useState } from "react";
import ThemeContext from "../../contexts/Main";
import Button, { ButtonKind, ButtonSize } from "../Button";
import Icon from "../Icons";
import { useRouter } from "next/router";
import ConnectWalletModel from "../Wallet/ConnectWalletModel";
import { PriceContext } from "../../pages/_app";
import ReCAPTCHA from "react-google-recaptcha";
import WalletConnectProvider from "@walletconnect/web3-provider";

type headerprops = {
  toggleSideMenu: () => void;
};
const STRIPE_CLU3_ACCESS_TOKEN = "f495070cabef89be5a8d328457357c0a";

const Header: React.FC<headerprops> = ({ toggleSideMenu, ...props }) => {
  const { theme } = useContext(ThemeContext);
  const router = useRouter();
  const { pathname, query } = router;
  const { access, utm_campaign } = query;
  const stripeUser = access === STRIPE_CLU3_ACCESS_TOKEN;
  const [walletConnectLabel, setWalletConnectLabel] =
    useState("Connect Wallet");
  const reRef = useRef<ReCAPTCHA>();
  const [isLoading, setLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [isAnimoca, setAnimoca] = useContext(PriceContext);
  const [web3provider, setWeb3provider] = useState<any>(null);

  useEffect(() => {
    //isConnected();
  }, []);

  const isConnected = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length) {
        setWalletConnectLabel("Wallet Connected");
      }
    }
  };

  const handleWallet = async () => {
    if (window.ethereum) {
      const [account] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletConnectLabel("Wallet Connected");
    } else {
      const notifier = (await import("codex-notifier")).default;
      notifier.show({
        message: `Please install <a href="https://metamask.io/download/" target="_blank">Metamask</a> Browser extension and login to your <br/><strong>Crypto-wallet</strong> before connecting`,
        style: "error",
        time: 5000,
      });
    }
  };

  const getUrl = (pathName: string) => {
    return {
      pathname: pathName,
      query: { ...router.query },
    };
  };

  useEffect(() => {

    function handleWalletChanges() {
      setWalletAddress("");
      setAnimoca({
        animoca: false,
        wallet : "",
        web3provider: null
      });
    }

    if (isAnimoca.web3provider) {
      isAnimoca.web3provider.on("chainChanged", handleWalletChanges);
      isAnimoca.web3provider.on("accountsChanged", handleWalletChanges);
      isAnimoca.web3provider.on("disconnect", handleWalletChanges);
    }

    return () => {
      if (isAnimoca.web3provider) {
        isAnimoca.web3provider.removeListener("chainChanged", handleWalletChanges);
        isAnimoca.web3provider.removeListener("accountsChanged", handleWalletChanges);
        isAnimoca.web3provider.removeListener("disconnect", handleWalletChanges);
        if(isAnimoca.web3provider instanceof WalletConnectProvider && isAnimoca.web3provider.connected){
          isAnimoca.web3provider.close();
        }
      }
    }
  }, [isAnimoca.web3provider]);

  useEffect(() => {
    async function getWallet() {
      if(walletAddress){
        setLoading(true);
        const token = await reRef?.current?.executeAsync();
        reRef?.current?.reset();
        const formBody = { wallet: walletAddress, token: token };
        const response = await fetch("/api/get-wallet", {
          method: "POST",
          body: JSON.stringify(formBody),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        setLoading(false);
        const { data, error } = await response.json();
        if(error){
          console.log(error);
        }

        if(data && data.utmCampaign == "Animoca"){
          setAnimoca({
            animoca: true,
            wallet : walletAddress,
            web3provider: web3provider
          });
        }else {
          setAnimoca({
            animoca: false,
            wallet : walletAddress,
            web3provider: web3provider
          });
        }
      }
    }
    getWallet();
  }, [walletAddress]);

  const closeModel = () => {
    setShowModel(false);
  };

  return (
    <div className="header flex items-center xl:justify-between fnt-medium">
      <div className="left flex items-center justify-start flex-grow xl:flex-grow-0 xl:hidden">
        <Link href={getUrl("/memberships")}>
          <a
            className={`xl:hidden ${
              pathname === "/memberships" && "green-text"
            }`}
          >
            Membership
          </a>
        </Link>
        {!stripeUser && (
          <Link href={getUrl("/how-it-works")}>
            <a className={`${pathname === "/how-it-works" && "green-text"}`}>
              How it Works
            </a>
          </Link>
        )}
        <Link href={getUrl("/join")}>
          <a data-ctalink className={`${pathname === "/join" && "green-text"}`}>
            Join
          </a>
        </Link>
      </div>
      <div className="flex items-center justify-between md:flex-grow mobile-header">
        <Icon
          icon="hamburger"
          size={40}
          className="hamburger m-8 ml-0 cursor-pointer"
          onClick={toggleSideMenu}
        />
        <Link href={getUrl(utm_campaign ? "/main" : "/")}>
          <a className="logo flex justify-center items-center w-max flex-grow xl:flex-grow-0 md:hidden">
            <Icon
              icon="club3-small-logo"
              className="logo-icon"
              size={115}
              color={theme.lightGreen}
            ></Icon>
          </a>
        </Link>
      </div>
      <ul className="right flex items-center justify-end flex-grow xl:flex-grow-0 w-1/4">
        <Link href="https://blog.metahollywood.io/">
          <a className={`xl:hidden`}>Blog</a>
        </Link>
        <Link href={getUrl("/locations")}>
          <a
            className={`xl:hidden ${pathname === "/locations" && "green-text"}`}
          >
            Location
          </a>
        </Link>
        <Link href={getUrl("/about")}>
          <a className={`md:hidden ${pathname === "/about" && "green-text"}`}>
            About
          </a>
        </Link>
        {/* { !stripeUser && (<Button
          kind={ButtonKind.primaryCta}
          size={ButtonSize.medium}
          cta={true}
          className="md:hidden"
          onClick={() => setShowModel(true)}
          disabled={isLoading? true: false}
        >
          {isAnimoca.wallet ? "Wallet Connected" : walletConnectLabel}
        </Button>) } */}
      </ul>
      {/* <ReCAPTCHA
        sitekey={String(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY)}
        size="invisible"
        ref={reRef as React.RefObject<ReCAPTCHA>}
      />
      <ConnectWalletModel
        open={showModel}
        closeModel={closeModel}
        setWalletAddress={setWalletAddress}
        setWeb3provider={setWeb3provider}
      /> */}
      <style jsx>{`
        .header {
          position: sticky;
          z-index: 100;
          top: 0;
          width: 100%;
          height: 120px;
        }
        .header .left,
        .header .right {
          padding: 16px 0px;
        }
        .header .left a {
          margin-right: 41px;
        }
        .header .right a {
          margin-right: 41px;
        }
        .logo :global(.logo-icon) {
          margin-right: 17px;
        }
        .header :global(.hamburger) {
          display: none !important;
        }
        @media screen and (max-width: ${theme.breakpoints.xl}px) {
          .header :global(.hamburger) {
            display: block !important;
          }
        }
      `}</style>
      <style jsx>{`
        .header {
          font-size: ${theme.sizes.content.p6.fontSize}px;
          line-height: ${theme.sizes.content.p6.lineHeight}px;
          border-color: ${theme.borderColor};
          background-color: ${theme.bgPrimary};
          padding: 16px ${theme.spacing.xxl.x}px;
        }
        @media screen and (max-width: ${theme.breakpoints.xl}px) {
          .header {
            padding: 16px ${theme.spacing.xl.x}px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.lg}px) {
          .header {
            padding: 16px ${theme.spacing.lg.x}px;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .header {
            padding: 16px ${theme.spacing.md.x}px;
            height: 80px;
            border-bottom: unset;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .header {
            padding: 16px ${theme.spacing.sm.x}px;
            height: 45px;
          }
          :global(.mobile-header svg.hamburger) {
            height: 18px !important;
            width: 18px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Header;
