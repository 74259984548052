import SocialImg from "../../public/images/membership/card/social.png";
import FoundingImg from "../../public/images/membership/card/founder.png";
import CorporateImg from "../../public/images/membership/card/corporate.png";
import GlobalImg from "../../public/images/membership/card/global-upgrade.png";

export const carouselData = [
  {
    title: "SOCIAL MEMBERSHIP ",
    ctaText: "LEARN MORE",
    ctaUrl: "/memberships",
    description: "Expand your network and enjoy unlimited access to our growing list of members-only clubs and private experiences.",
    img: SocialImg,
    video: "/videos/social.mp4",
  },
  {
    title: "FOUNDING MEMBERSHIP ",
    ctaText: "LEARN MORE",
    ctaUrl: "/memberships",
    description:
      "VIP access to Club 3, exclusive events and Founders' only club spaces, restaurants and lounges.",
    img: FoundingImg,
    video: "/videos/founder.mp4",
  },
  {
    title: "CORPORATE MEMBERSHIP ",
    ctaText: "CONTACT CONCIERGE",
    ctaUrl: "mailto:concierge@club3members.com",
    description:
      "Unlimited access to Club 3, member events and select club amenities.",
    img: CorporateImg,
    video: "/videos/corporate.mp4",
  },
  {
    title: "GLOBAL UPGRADE",
    ctaText: "LEARN MORE",
    ctaUrl: "/memberships",
    description:
      "Add a global upgrade to your membership for access to our growing list of Club 3 locations around the world.",
    img: GlobalImg,
    video: "/videos/global.mp4",
  },
];
